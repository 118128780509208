
import { PageTitle } from '../../components/PageElements'



function NotFound() {
  return (
    <div style={{maxWidth: '1200px', padding: '0px 20px', margin: 'auto'}} >
      <div className="page-container">
        <PageTitle text={'404 Página não encontrada'} />
      </div>
      <div className='wrapper-container-content bgwhite' >
        <div className='wrapper-page-content' id="wrapper-page-content">
          
        </div>
      </div>  
    </div>
  )
}

export default NotFound
