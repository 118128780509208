import './style.scss'

interface ITab {
  id: number
  label: string
}

interface ITabs {
  tabs: ITab[]
  active?: number | null
  onChange: any
  className?: string
}

function Tabs(props: ITabs) {
  const { tabs, active, onChange, className } = props

  return (
    <div className={`tabs-container ${className}`}>
      {tabs?.map((tab, index) => {
        return (
          <div
            className={`tabs-item ${active === index ? 'active' : ''}`}
            id={`tabs_${tab?.id || '0'}-${index}`}
            key={index}
            onClick={() => onChange(tab.id)}
          >
            {tab?.label}
          </div>
        )
      })}
    </div>
  )
}

export default Tabs
