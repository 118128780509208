import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

import {
  TextField,
  Menu,
  MenuItem,
  Select,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  IconButton
} from '@mui/material'

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SearchIcon from '@mui/icons-material/Search';

import Modal from '../Modal'
import { ILog } from '../../interfaces/index'
import services from '../../services/index.js'

import { debounce } from 'lodash'
import { useAuth } from '../../hooks/useAuth'

interface ITableaLog {
  mainList: ILog[] | [] | null | undefined
  handlePagination: any
  handleBusca:any
  currentPage: number
  totalPages: number
  pageSize: number
  showLinkUser: boolean
}

function TabelaAcoes(props: ITableaLog) {
  const { 
    mainList, handleBusca, pageSize, currentPage, totalPages, handlePagination, showLinkUser
  } = props;
  const navigate = useNavigate()
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [modalDetalheLog, setModalDetalheLog] = useState(false)
  const [logActive, setLogActive] = useState<ILog>(
    {
      id: 0,
      userId: 0,
      success: '',
      ip: '',
      browserAgent: '',
      createdAt: '',
      requestDateTime: '',
      user: {}
    }
  )

  const [anchorElBusca, setAnchorElBusca] = useState<null | HTMLElement>(null);
  const openBusca = Boolean(anchorElBusca);
  const handleClickBusca = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBusca(event.currentTarget);
  };
  const handleCloseBusca = () => {
    setAnchorElBusca(null);
  };

  const handleSelectLog = async (item: ILog) => {
    setLogActive(item)
    setModalDetalheLog(true)
  }

  const [filterList, setFilterList] = useState<any>([])
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('id&&&')
  const [paginacao, setPaginacao] = useState<number[] | []>([])

  const ativaOrdenacao = true

  const openIPLink = (ip: string) => {
    if (ip) {
      let baseIpLink = 'https://ipinfo.io/'
      window.open(`${baseIpLink}${ip}`, '_blank')
    }
  }

  const openUserDetalhe = (idUser: number) => {
    navigate(`/app/users/user/${idUser}`)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(colunaBusca + busca, pageSize)
  }, 800),  [colunaBusca, pageSize])

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(colunaBusca + search, pageSize, column, direction)
    } else {
      handleBusca(colunaBusca + search, pageSize, colunaAtiva, direction)
    }
  }

  useEffect(() => {
    setFilterList(mainList?.map((item:ILog) => {
      return item
    }))
  }, [mainList])

  useEffect(() => {
    setPaginacao(services.utils.getPaginationItens(totalPages, currentPage))
  }, [totalPages, currentPage])

  return (
    <>
      <div className='wrapper-page-content-interna'>
        <div className='flex items-center justify-between gap-10'>
          <div className='flex gap-10'></div>
          
          <div className='flex items-center gap-10'>
            <div className='flex items-center gap-6'>
              <TextField
                size='small'
                className='w-200'
                label='Pesquisa'
                placeholder='Pesquisa'
                inputProps={{ maxLength: 50 }}
                value={search}
                onChange={e => handleChangeBusca(e.target.value)}
                disabled={promiseInProgress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                        aria-label="toggle password visibility"
                        onClick={(e) => handleClickBusca(e)}
                        edge="end"
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>

          </div>
        </div>
        <div className='divider' style={{opacity: 0}}></div>
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  <div>
                    <span className='th-title'>ID</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'id')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'id')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'> Data</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'requestDateTime')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'requestDateTime')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'> IP</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'ip')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'ip')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'>Ação</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'method')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'method')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'>Usuário</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'user')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'user')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th><div><span className='th-title'>Ações</span></div></th>
              </tr>
            </thead>
            <tbody>
              {!filterList && 
                <tr><td colSpan={6}><div className='justify-center'>loading...</div></td></tr>
              }
              {filterList?.length === 0 && 
                <tr><td colSpan={6}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
              }
              {filterList?.map((item: ILog, index: number) => (
                <tr key={item.id}>
                  <td><div>{item.id}</div></td>
                  <td><div>{item.requestDateTime ? moment(item.requestDateTime).format('DD/MM/YYYY HH:mm') : '-'}</div></td>
                  <td><div>{item.ip}</div></td>
                  <td><div>{item.method}</div></td>
                  <td><div>{item.user.fullName}</div></td>
                  <td>
                    <div className='justify-start'>
                      <IconButton  
                        size="small" aria-label="detalhe" component="label" 
                        onClick={() => handleSelectLog(item)}
                        disabled={promiseInProgress}
                      >
                        <RemoveRedEyeIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {currentPage && filterList?.length ?
            <div className='flex justify-end items-center gap-10'>
              <div className='table-pagination'>
                <IconButton 
                  size="small" aria-label="primeira pagina" component="label" 
                  onClick={() => handlePagination(1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage <= 1}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton 
                  size="small" aria-label="pagina anterior" component="label" 
                  onClick={() => handlePagination(currentPage - 1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage <= 1}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                {paginacao?.map(page => (
                  <Fragment key={page.toString()}>
                    {page === currentPage ? <div className='table-pagination_number active'>{page.toString()}</div> : 
                    <IconButton 
                      className='table-pagination_number'
                      size="small" aria-label="Próxima pagina" component="label" 
                      onClick={() => handlePagination(page, colunaBusca + search, pageSize )}
                    >
                      {page.toString()}
                    </IconButton>
                   }
                  </Fragment>
                ))}
                <IconButton 
                  size="small" aria-label="Próxima pagina" component="label" 
                  onClick={() => handlePagination(currentPage + 1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage >= totalPages}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
                <IconButton 
                  size="small" aria-label="ultima pagina" component="label" 
                  onClick={() => handlePagination(totalPages, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage >= totalPages}
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </div>
              <Select
                style={{width: '100px'}}
                fullWidth
                size="small"
                value={pageSize || ''}
                disabled={promiseInProgress}
                onChange={e =>
                  handlePagination(1, colunaBusca + search, e.target.value )
                }
              >
                <MenuItem value="10">10 Itens</MenuItem>
                <MenuItem value="20">20 Itens</MenuItem>
                <MenuItem value="50">50 Itens</MenuItem>
                <MenuItem value="100">100 Itens</MenuItem>
              </Select>
            </div> : ''
          }
        </div>
      </div>

      {/* menu opções busca */}
      <Menu
        anchorEl={anchorElBusca}
        open={openBusca}
        onClose={handleCloseBusca}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBusca(e.target.value)}
                value={colunaBusca}
              >
                <FormControlLabel value="id&&&" control={<Radio />} label="ID" />
                <FormControlLabel value="ip&&&" control={<Radio />} label="IP" />
                <FormControlLabel value="method&&&" control={<Radio />} label="Ação" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>

      {/* modal detalhe log */}
      <Modal
        size='sm'
        open={modalDetalheLog}
        close={() => setModalDetalheLog(false)}
        titulo={`Registro ${logActive.id}`}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <p className="m-0">
              <strong>Dados da requisição</strong>
            </p>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              disabled
              size='small'
              label="IP"
              inputProps={{ maxLength: 400 }}
              value={logActive.ip}
              InputProps={{
                endAdornment: <InputAdornment position="start" style={{marginRight: '0px'}}>
                  <IconButton
                    style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                    aria-label="toggle password visibility"
                    onClick={() => openIPLink(logActive.ip)}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>  
                </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              disabled
              size='small'
              label="Endpoint"
              inputProps={{ maxLength: 400 }}
              value={logActive.endpoint}
              InputProps={{
                endAdornment: <InputAdornment position="start" style={{marginRight: '-6px'}}>
                  <div style={{fontSize: '10px', borderRadius: '4px', backgroundColor: '#f4f4f4', padding: '0px 6px'}}>
                    {logActive.method}
                  </div>
                </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              disabled
              size='small'
              label="Usuário"
              inputProps={{ maxLength: 400 }}
              value={logActive.user.email}
              InputProps={{
                endAdornment: <>
                  {showLinkUser && userRoles.includes("user_read") ? <InputAdornment position="start" style={{marginRight: '0px'}}>
                    <IconButton
                      style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                      aria-label="toggle password visibility"
                      onClick={() => openUserDetalhe(logActive.user.id)}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>  
                  </InputAdornment> : ''
                  }
                </>
                ,
              }}
            />
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              disabled
              size='small'
              label="Data"
              inputProps={{ maxLength: 400 }}
              value={logActive.requestDateTime}
            />
          </Grid>

          <Grid item md={8} sm={12} xs={12}>
            <TextField
              fullWidth
              disabled
              size='small'
              label="Tipo de navegador"
              inputProps={{ maxLength: 400 }}
              value={logActive.requestUserAgent}
            />
          </Grid>

          <Grid item xs={12}>
            <p className="m-0">
              <strong>Payload da requisição</strong>
            </p>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              multiline
              disabled
              size='small'
              label="Payload"
              inputProps={{ maxLength: 400 }}
              value={logActive.requestPayload}
            />
          </Grid>
          
        </Grid>
      </Modal>
    </>
  )
}

export default TabelaAcoes
