import { memo, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'


import {
  Tooltip,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import GroupIcon from '@mui/icons-material/Group';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

import { useAuth } from '../../hooks/useAuth'
import { useLoading } from '../../hooks/useLoading'

import './menu.scss'

const SystemMenu = () => {
  const location = useLocation()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { pathname } = location
  const splitLocation = pathname.split('/')

  const { removeLoading } = useLoading()

  useEffect(() => {
    document.body.classList.remove('m-s-o')
    removeLoading()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const openColapseMenu = (subMenuId: string) => {
    const subMenu = document.getElementById(subMenuId)
    if (subMenu) subMenu.classList.toggle('show')
  }

  const handleAutoCloseMenu = () => {
    if (document.body.clientWidth > 768) {
      closeMainMenu()
    }
  }

  const closeMainMenu = () => {
    document.body.classList.remove("m-l-o");
    document.body.classList.remove("m-s-o");
  }

  const toggleContainerMenu = () => {
    const menu_colapse = document.body.classList.contains("menu_colapse");
    if (menu_colapse) {
      document.body.classList.remove("menu_colapse");
    } else {
      document.body.classList.add("menu_colapse");
    }
  }

  return (
    <>
      <div className="system-menu-sidebar">
        <div className='system-menu-sidebar_titleMenu_close' style={{fontSize: '14px', textAlign: 'center', fontWeight: 'bold'}}>
          <div>Menu</div> <span onClick={closeMainMenu}><HighlightOffIcon /></span>
        </div>
        <div className='system-menu-sidebar_titleMenu toggle_mainMenu' onClick={toggleContainerMenu} >
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <ul>
          {userRoles.includes('user_read') &&
            <li className={splitLocation[2] === 'users' ? 'active' : ''}>
              <Link to="/app/users/todos" onClick={closeMainMenu} className='flex justify-between items-center'>
                <span>Usuário</span>  
                <Tooltip title="Usuário" onClick={toggleContainerMenu} placement="right">
                  <GroupIcon/>
                </Tooltip>
              </Link>
            </li>
          }
          {/* {userRoles.includes('function_read') &&
            <li className={splitLocation[2] === 'cargos' ? 'active' : ''}>
              <Link to="/app/cargos/todos" onClick={closeMainMenu} className='flex justify-between items-center'>
                <span>Cargos</span>
                <BusinessCenterIcon/>
              </Link>
            </li>
          } */}
          {userRoles.includes('app_read') &&
            <li className={splitLocation[2] === 'apps' ? 'active' : ''}>
              <Link to="/app/apps/todos" onClick={closeMainMenu} className='flex justify-between items-center'>
                <span>Apps</span>
                <Tooltip title="Apps" onClick={toggleContainerMenu} placement="right">
                  <WidgetsIcon/>
                </Tooltip>
              </Link>
            </li>
          }
          {(userRoles.includes('log_action_read') || userRoles.includes('log_login_read')) &&
            <li className={splitLocation[2] === 'logs' ? 'active' : ''}>
              <Link to="/app/logs/todos" onClick={closeMainMenu} className='flex justify-between items-center'>
                <span>Logs</span>
                <Tooltip title="Logs" onClick={toggleContainerMenu} placement="right">
                  <ClearAllIcon/>
                </Tooltip>
              </Link>
            </li>
          }
          {/* {(userRoles?.includes('ADMINISTRATOR') || userRoles?.includes('CUSTOMER_ADMINISTRATOR')) &&
            <li
              id="subMenu-logs"
              className={
                ['dash_management'].includes(
                  splitLocation[2]
                )
                  ? 'active show'
                  : ''
              }
            >
              <button
                className={
                  ['dash_management'].includes(
                    splitLocation[2]
                  )
                    ? 'active btn-noStile'
                    : 'btn-noStile'
                }
                onClick={() => openColapseMenu('subMenu-logs')}
              >
                <span>
                  <ClearAllIcon/> Logs
                </span>
                <ArrowForwardIosIcon
                  fontSize="small"
                  className="subMenu-icon-arrow"
                />
              </button>
              <ul className='system-menu-sidebar-inside'>
                <li
                  className={
                    ['functionalities'].includes(splitLocation[3])
                      ? 'active'
                      : ''
                  }
                  style={{paddingTop: '0px'}}
                >
                  <Link
                    to="/app/logs/todos"
                    onClick={closeMainMenu}
                  >
                    Log A
                  </Link>
                </li>
              </ul>
            </li>
          } */}
          
        </ul>
      </div>
      <span className="system-menu-sidebar-backdrop" onMouseEnter={handleAutoCloseMenu}></span>
    </>
  )
}

export default memo(SystemMenu)
