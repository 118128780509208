import React, { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { PageTitle } from '../../components/PageElements'
import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IUser, ICargo, IBusca } from '../../interfaces/index'

import TabelaCargos from './TabelaCargos'

import services from '../../services/index.js'

import './style.scss'

enum UserMode {
  novo,
  edicao
}

function Cargos() {
  const { promiseInProgress } = usePromiseTracker();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [modalCargo, setModalCargo] = useState(false)
  const [modalRemoverCargo, setModalRemoverCargo] = useState<boolean>(false)

  const [cargos, setCargos] = useState<ICargo[] | [] | null | undefined>(null)
  const [cargosAll, setCargosAll] = useState<ICargo[] | [] | null | undefined>(null)
  const [cargoActive, setCargoActive] = useState<ICargo>(
    {
      name: "",
      id: 0
    }
  )

  const [userMode, setUserMode] = useState<UserMode>(UserMode.novo)
 
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const getCargoAll = useCallback(async () => {
    try {
      const res = await trackPromise(services.api.getCargosAll())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Cargos'
        )
      }
      setCargosAll(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCargos = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})

      const res = await trackPromise(services.api.getCargosAllPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Cargos'
        )
      }
      
      setCargos(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getCargos({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getCargos({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const handleNewCargo = useCallback(async () => {
    getCargoAll()
    setCargoActive(
      {
        id: 0,
        name: ""
      }
    )
    setUserMode(UserMode.novo)
    setModalCargo(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEdit = useCallback(async (cargoItem:ICargo) => {
    getCargoAll()
    setCargoActive(
      {
        id: cargoItem.id,
        name: cargoItem.name,
        parentFunctionId: cargoItem.parentFunctionId,
        description: cargoItem.description
      }
    )
    setUserMode(UserMode.edicao)
    setModalCargo(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRemoverCargo = async (user:IUser) => {
    setCargoActive(
      {
        id: user.id,
        name: user.name
      }
    )
    setModalRemoverCargo(true)
  }

  const saveUpdateCargo = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    function reloadNewUser() {
      setModalCargo(false)
      getCargos()
    }

    async function criarCargo() {
      try {
        const res = await trackPromise(services.api.criarCargo(cargoActive.name, cargoActive.description, cargoActive.parentFunctionId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível salvar Cargo'
          )
        }
        notify('Cargo salvo com sucesso', { variant: 'success' })
        reloadNewUser()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    async function editarCargo() {
      try {
        const res = await trackPromise(services.api.editCargo(cargoActive.name, cargoActive.description, cargoActive.parentFunctionId, cargoActive.id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar Cargo'
          )
        }
        notify('Cargo editado com sucesso', { variant: 'success' })

        let cargoName:string | undefined = ''
        if (cargoActive.parentFunctionId) {
          let cargoSelected = cargos?.filter((item: ICargo) => item.id === cargoActive.parentFunctionId)
          if(cargoSelected?.length) {
            cargoName = cargoSelected[0].name
          }
        }
        setCargos(
          cargos?.map(item => 
            item.id === cargoActive.id 
            ? {...item, 
              name : cargoActive.name,
              parentFunctionId: cargoActive.parentFunctionId,
              parentFunctionName: cargoName,
              description: cargoActive.description
            }
            : item 
        ))
        setModalCargo(false)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (cargoActive.id) {
      // edição
      editarCargo()
    } else {
      // novo
      criarCargo()
    }
    
  }

  const excluirCargo = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    try {
      const res = await trackPromise(services.api.excluirCargo(cargoActive.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível excluir Cargo'
        )
      }
      notify('Cargo excluido com sucesso', { variant: 'success' })
      setModalRemoverCargo(false)
      setCargos(
        cargos?.filter(item => item.id !== cargoActive.id)
      )
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }

  useEffect(() => {
    getCargos()
  }, [getCargos])

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div className='flex w-full justify-between items-center'>
            <div>Gerenciamento de Cargos</div>
            {userRoles.includes('function_create') &&
              <Button
                variant="contained"
                className="btn-main"
                size="small"
                type="button"
                onClick={(e) => handleNewCargo()}
                disabled={promiseInProgress}
                startIcon={<AddCircleIcon style={{color:'white'}} fontSize='small' />}
              >
                NOVO
              </Button>
            }
          </div>
        </PageTitle>

        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                <TabelaCargos 
                  mainList={cargos} 
                  editRegistro={handleEdit}
                  handleRemoverRegistro={handleRemoverCargo}
                  handlePagination={handlePagination}
                  handleBusca={handleBusca}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        
      </div>

      {/* modal cargo novo/edit */}
      <Modal
        size='sm'
        open={modalCargo}
        close={() => setModalCargo(false)}
        titulo={userMode === UserMode.edicao ? 'Edição de Cargo' : 'Novo Cargo'}
      >
        <form onSubmit={saveUpdateCargo} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome da Função"
                inputProps={{ maxLength: 400 }}
                value={cargoActive.name}
                onChange={e => 
                  setCargoActive(prevState => ({
                    ...prevState,
                    name: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Função superior</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  size="small"
                  label="Função superior"
                  value={cargoActive.parentFunctionId || ''}
                  onChange={e =>
                    setCargoActive(prevState => ({
                      ...prevState,
                      parentFunctionId: e.target.value
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  {cargosAll?.map((item: any) => (
                    <MenuItem key={item.id} value={item.id} >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Descrição"
                inputProps={{ maxLength: 400 }}
                value={cargoActive.description}
                onChange={e => 
                  setCargoActive(prevState => ({
                    ...prevState,
                    description: e.target.value
                  }))
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalCargo(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal excluir cargo */}
      <Modal
        size='sm'
        open={modalRemoverCargo}
        close={() => setModalRemoverCargo(false)}
        titulo={'Excluir Cargo'}
      >
        <form onSubmit={excluirCargo} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <div className='flex gap-8 items-center justify-center flex-nowrap'>
                <ErrorOutlineIcon style={{color: 'red'}} />
                <div>Para que o cargo possa ser excluído, nenhum usuário pode estar atrelado ao mesmo.</div>
              </div>
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalRemoverCargo(false)}
              >
                cancelar
              </Button> 
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

    </div>
  )
}

export default Cargos
