import {
  Button
} from '@mui/material'

import SettingsIcon from '@mui/icons-material/Settings';

import "./style.scss";
import transparenteImg from '../../assets/img/transparentImg.png'

interface ICardApp {
  img?: string
  title?: string
  action?: () => void
}

const CardApp = (props: ICardApp) => {
  const { title, img, action } = props
  return (
    <div className="appCard_Item">
      <div className="appCard_Item_Imagem" style={{backgroundImage: `url(${img})`}} >
        <img src={transparenteImg} alt='' />
      </div>
      <div className="appCard_Item_Content">
        <div>{title}</div>
        <Button
          variant="contained"
          type="button"
          size='small'
          color="secondary"
          startIcon={<SettingsIcon fontSize="inherit" />}
          onClick={action}
        >
          EDITAR
        </Button>
      </div>
    </div>
  );
}

export default CardApp;
