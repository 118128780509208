import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from '@mui/material/styles';
import {
  Grid,
  TextField,
  Button,
  IconButton
} from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useNotification } from '../../hooks/useNotification.js'
import { IUser } from '../../interfaces/index'
import services from '../../services/index.js'
import Modal from '../Modal'
import SystemMenu from '../Menu'

import logo from "../../assets/img/allis-logo.png";
import logoIcon from "../../assets/img/allis-icon.png";
import "./style.scss";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function IconToggleMenu() {
  const toggleMenu = () => {
    const wrapperMenuLargeOpen = document.body.classList.contains("m-l-o");
    const wrapperMenuSmallOpen = document.body.classList.contains("m-s-o");

    if (document.body.clientWidth > 768) {
      if (wrapperMenuLargeOpen) {
        document.body.classList.remove("m-l-o");
      } else {
        document.body.classList.add("m-l-o");
      }
    }

    if (wrapperMenuSmallOpen) {
      document.body.classList.remove("m-s-o");
    } else {
      if (document.body.clientWidth <= 992) {
        document.body.classList.add("m-s-o");
      }
    }
  };

  return (
    <>
      <BootstrapTooltip title="Menu" placement="top">
        <IconButton className="system-main-menu-icon" onClick={toggleMenu}>
          <MenuIcon />
        </IconButton>
      </BootstrapTooltip>
    </>
  );
}

function LogoutMenu() {
  const navigate = useNavigate();

  const logOut = () => {
    Cookies.remove("TradeTalentosTokenPAT");
    Cookies.remove("TradeTalentosUserPAT");
    navigate("/");
  };

  useEffect(() => {
    const menuContainer = document.getElementById('wrapper-container-submenu')
    const menuContainerAdmin = document.getElementById('wrapper-container-submenuAdmin')
    
    if (menuContainer && window.outerWidth <= 768) menuContainer.classList.toggle('hide')
    if (menuContainerAdmin && window.outerWidth <= 768) menuContainerAdmin.classList.toggle('hide')
  }, []);

  return (
    <BootstrapTooltip title="Sair" placement="top">
      <div
        id="system-main-header-logout-button"
        className="system-main-header-logout-button"
        onClick={logOut}
      >
        {/* <div className="icon-name">{userIniciais}</div> */}
        <div className="icon-name"><LogoutIcon /> <span>Sair</span></div>
      </div>
    </BootstrapTooltip>
  );
}

function UserMenu(props:any) {
  const { action } = props
  return (
    <BootstrapTooltip title="Meus dados" placement="right">
      <div
        id="system-main-header-user-button"
        className="system-main-header-user-button"
        onClick={action}
      >
        <div className="icon-name"><PersonOutlineIcon /></div>
      </div>
    </BootstrapTooltip>
  );
}

function Header() {
  const { notify } = useNotification()
  const { promiseInProgress } = usePromiseTracker();

  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [modalUsuario, setModalUsuario] = useState(false)
  const [userActive, setUserActive] = useState<IUser>(
    {
      name: '',
      phone: '',
      loginBlocked: false,
      updatePasswordOnLogin: false,
      username: '',
      email: '',
      company: '',
      internal: false,
      role: '',
      active: true,
      disabledByLoginTime: false,
      unblockDate: false,
      sraBlocked: false,
      skipSraCheck: false
    }
  )

  const saveUpdateUser = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    async function editarUsuario() {
      try {
        const res = await trackPromise(services.api.editUsuarioMe(userActive))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar cadastro'
          )
        }
        notify('Cadastro editado com sucesso', { variant: 'success' })
        setModalUsuario(false)
        setConfirmarSenha('')
        setUserActive(prevState => ({
          ...prevState,
          editado: false
        }))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (userActive.id) {
      if (userActive.password) {
        if (userActive.password.length < 6) {
          notify('Informe uma senha com pelo menos 6 caracteres', { variant: 'warning' })
          return
        }
        if (userActive.password !== confirmarSenha) {
          notify('Senhas informadas não são iguais', { variant: 'warning' })
          return
        }
      }
      editarUsuario()
    }
  }

  const getDadosPessoais = async () => {
    try {
      const res = await trackPromise(services.api.getUserMe())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível carregar daddos'
        )
      }
      
      setModalUsuario(true)
      setUserActive(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }

  return (
    <>
      <header className="system-main-header">
        <div className="system-main-header-logo-2">
          <img src={logo} className="header-logo-destop" alt="Trade & Talentos" />
          <img src={logoIcon} className="header-logo-mobile" alt="Trade & Talentos" />
        </div>
        <SystemMenu />
        <IconToggleMenu />
        {/* <UserMenu action={getDadosPessoais} /> */}
        <LogoutMenu />
      </header>

      {/* modal usuário */}
      <Modal
        size='sm'
        open={modalUsuario}
        close={() => setModalUsuario(false)}
        titulo={'Meus dados'}
      >
        <form onSubmit={saveUpdateUser}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="m-0">
                Dados
              </p>
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nome</span>
                <Tooltip title="O nome do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                inputProps={{ maxLength: 400 }}
                value={userActive.name}
                disabled={true}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    name: e.target.value,
                    editado: true
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>E-mail</span>
                <Tooltip title="O e-mail do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                type="email"
                inputProps={{ maxLength: 400 }}
                value={userActive.email}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    email: e.target.value,
                    editado: true
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Telefone</span>
                <Tooltip title="O Telefone do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                inputProps={{ maxLength: 400 }}
                value={services.mask.phone(userActive.phone)}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    phone: services.mask.unMaskPhone(e.target.value),
                    editado: true
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>UserName</span>
                <Tooltip title="O UserName do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                disabled={true}
                inputProps={{ maxLength: 400 }}
                value={userActive.username}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    username: e.target.value,
                    editado: true
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Empresa</span>
                <Tooltip title="O Empresa do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size='small'
                disabled={true}
                // disabled={!userRoles?.includes('ADMINISTRATOR')}
                inputProps={{ maxLength: 400 }}
                value={userActive.company}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    company: e.target.value,
                    editado: true
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Senha</span>
                <Tooltip title="O Senha do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size='small'
                type="password"
                inputProps={{ maxLength: 20 }}
                value={userActive.password}
                onChange={e => 
                  setUserActive(prevState => ({
                    ...prevState,
                    password: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Confirmar Senha</span>
              </label>
              <TextField
                fullWidth
                size='small'
                type="password"
                inputProps={{ maxLength: 20 }}
                value={confirmarSenha}
                onChange={e => 
                  setConfirmarSenha(prevState => e.target.value)
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
}

export default Header;
