import { PageTitle } from '../../components/PageElements'

function NotFound() {
  return (
    <div style={{maxWidth: '1200px', padding: '0px 20px', margin: 'auto'}} >
      <div className="page-container">
        <PageTitle text={'Acesso negado'} />
        <div className='wrapper-container-content bgwhite' >
          <div className='wrapper-page-content' id="wrapper-page-content">
            Você não tem acesso a esta página, contate o administrador
          </div>
        </div>  
      </div>
    </div>
  )
}

export default NotFound
