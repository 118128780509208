import { useState, useEffect, useCallback } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { Grid } from '@mui/material'
import { ILog, IBusca } from '../../interfaces/index'

import Tabs from '../../components/Tabs'
import { PageTitle } from '../../components/PageElements'

import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import TabelaAutenticacao from '../../components/Logs/TabelaAutenticacao'
import TabelaAcoes from '../../components/Logs/TabelaAcoes'

import services from '../../services/index.js'

import './style.scss'

function Logs() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [abaActive, setAbaActive] = useState(null)
  const [tabsList, settTabsList] = useState<any[] | []>([])
  const [logsLogin, setLogsLogin] = useState<ILog[] | []>([])
  const [logsAction, setLogsAction] = useState<ILog[] | []>([])
  
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [currentPageAction, setCurrentPageAction] = useState(1)
  const [totalPagesAction, setTotalPagesAction] = useState(1)
  const [pageSizeAction, setPageSizeAction] = useState(20)

  const getLogsLogin = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})

      const res = await trackPromise(services.api.getLogsLoginPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os Logs'
        )
      }

      setLogsLogin(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLogsLoginAction = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})

      const res = await trackPromise(services.api.getLogsActionPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os Logs'
        )
      }

      setLogsAction(res.data.data)
      setCurrentPageAction(res.data.pageIndex)
      setPageSizeAction(res.data.pageSize)
      setTotalPagesAction(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getLogsLogin({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage: number, coluna?: string, desc?: boolean) => {
    getLogsLogin({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaginationAction = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getLogsLoginAction({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBuscaAction = useCallback(async (busca: string, sizePage: number, coluna?: string, desc?: boolean) => {
    getLogsLoginAction({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getLogsLogin()
    getLogsLoginAction()
  }, [getLogsLogin, getLogsLoginAction])

  useEffect(() => {
    let tabset: any = []
    if (userRoles) {
      if (userRoles.includes('log_action_read')) {
        tabset.push({id: 0, label: 'Ações'})
      }
      if (userRoles.includes("log_login_read")) {
        tabset.push({id: 1, label: 'Autenticação'})
      }
    }
    if(tabset?.length) {
      settTabsList(tabset)
      setAbaActive(tabset[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div>Logs do sistema</div>
        </PageTitle>

        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div>
              <Tabs tabs={tabsList} active={abaActive} onChange={setAbaActive} />
            </div>
            {abaActive === 0 && 
              <div className='panel'>
                <div className='panel-header'>
                  Registros de Ações
                </div>
                <div className='panel-body'>
                  <TabelaAcoes 
                    mainList={logsAction} 
                    handlePagination={handlePaginationAction}
                    handleBusca={handleBuscaAction}
                    currentPage={currentPageAction}
                    totalPages={totalPagesAction}
                    pageSize={pageSizeAction}
                    showLinkUser={true}
                  />
                </div>
              </div>
            }
            {abaActive === 1 && 
              <div className='panel'>
                <div className='panel-header'>
                  Registros de Autenticação
                </div>
                <div className='panel-body'>
                  <TabelaAutenticacao 
                    mainList={logsLogin} 
                    handlePagination={handlePagination}
                    handleBusca={handleBusca}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    
                  />
                </div>
              </div>
            }
            
          </Grid>
        </Grid>
      </div>


    </div>
  )
}

export default Logs
