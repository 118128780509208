import React from 'react'
import ReactDOM from 'react-dom/client'

import { LoadingBar } from './components/Loading'
import { AuthProvider } from './hooks/useAuth.js'
import { SnackbarProvider } from 'notistack'
import { LoadingProvider } from './hooks/useLoading.js'
import { NotificationProvider } from './hooks/useNotification.js'

import SystemRoutes from './routes/index'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'

import './styles/base.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <LoadingBar />
    <SnackbarProvider maxSnack={3}>
      <NotificationProvider>
        <LoadingProvider>
          <AuthProvider>
            <SystemRoutes />
          </AuthProvider>
        </LoadingProvider>
      </NotificationProvider>
    </SnackbarProvider>
  </>
)
