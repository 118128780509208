export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
  return get(key)
}
export const get = key => {
  const retorno = JSON.parse(localStorage.getItem(key))
  return retorno
}
export const remove = key => {
  localStorage.removeItem(key)
}

export const setSession = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value))
  return get(key)
}
export const getSession = key => {
  const retorno = JSON.parse(sessionStorage.getItem(key))
  return retorno
}
export const removeSession = key => {
  sessionStorage.removeItem(key)
}

export const getUser = () => {
  const user = JSON.parse(sessionStorage.getItem('User'))
  if (!user) return undefined
  return user
}
export const setUser = user => {
  setSession('User', user)
}

export const getToken = () => {
  const user = getSession('TOKEN')
  if (!user) return undefined
  const token = user.token?.serial
  return token
}
export const getTokenExpiration = () => {
  const user = getSession('TOKEN')
  if (!user) return undefined
  const token = user.token.expiration
  return token
}

export const clear = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const isAdmin = () => {
  const user = getSession('LEOSSO_USER')
  if (!user) return false
  if (!user.role) return false
  return user.role.includes('Admin_MVL')
}
