import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import moment from 'moment'

import {
  TextField,
  Menu,
  MenuItem,
  Select,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton
} from '@mui/material'

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { ILog } from '../../interfaces/index'
import services from '../../services/index.js'

import { debounce } from 'lodash'
import { useAuth } from '../../hooks/useAuth'

interface ITableaLog {
  mainList: ILog[] | [] | null | undefined
  handlePagination: any
  handleBusca:any
  currentPage: number
  totalPages: number
  pageSize: number
}

function TabelaAutenticacao(props: ITableaLog) {
  const { 
    mainList, handleBusca, pageSize, currentPage, totalPages, handlePagination
  } = props;

  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [anchorElBusca, setAnchorElBusca] = useState<null | HTMLElement>(null);
  const openBusca = Boolean(anchorElBusca);
  const handleClickBusca = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBusca(event.currentTarget);
  };
  const handleCloseBusca = () => {
    setAnchorElBusca(null);
  };

  const [filterList, setFilterList] = useState<any>([])
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('ip&&&')
  const [paginacao, setPaginacao] = useState<number[] | []>([])

  const ativaOrdenacao = true

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(colunaBusca + busca, pageSize)
  }, 800),  [colunaBusca, pageSize])

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(colunaBusca + search, pageSize, column, direction)
    } else {
      handleBusca(colunaBusca + search, pageSize, colunaAtiva, direction)
    }
  }

  useEffect(() => {
    setFilterList(mainList?.map((item:ILog) => {
      return item
    }))
  }, [mainList])

  useEffect(() => {
    setPaginacao(services.utils.getPaginationItens(totalPages, currentPage))
  }, [totalPages, currentPage])

  return (
    <>
      <div className='wrapper-page-content-interna'>
        <div className='flex items-center justify-between gap-10'>
          <div className='flex gap-10'></div>
          <div className='flex items-center gap-10'>
            <div className='flex items-center gap-6'>
              <TextField
                size='small'
                className='w-200'
                label='Pesquisa'
                placeholder='Pesquisa'
                inputProps={{ maxLength: 50 }}
                value={search}
                onChange={e => handleChangeBusca(e.target.value)}
                disabled={promiseInProgress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                        aria-label="toggle password visibility"
                        onClick={(e) => handleClickBusca(e)}
                        edge="end"
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          
          </div>
        </div>
        <div className='divider' style={{opacity: 0}}></div>
        <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  <div>
                    <span className='th-title'> Data</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'createdAt')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'createdAt')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'> IP</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'ip')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'ip')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'> Navegador</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'browserAgent')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'browserAgent')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                {/* <th>
                  <div>
                    <span className='th-title'>  Sistema</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'browserAgent')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'browserAgent')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th> */}
                <th>
                  <div>
                    <span className='th-title'> Usuário</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'user')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                          
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'user')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
                <th>
                  <div>
                    <span className='th-title'> Resultado</span>
                    {ativaOrdenacao &&
                      <div style={{display:'flex', flexDirection: 'column'}}>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(false,'success')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                        <IconButton  
                          size="small" 
                          onClick={() => ordeby(true,'success')}
                          style={{padding:'2px'}}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                        </IconButton>
                      </div>
                    }
                  </div>
                </th>
               
              </tr>
            </thead>
            <tbody>
              {!filterList && 
                <tr><td colSpan={6}><div className='justify-center'>loading...</div></td></tr>
              }
              {filterList?.length === 0 && 
                <tr><td colSpan={6}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
              }
              {filterList?.map((item: ILog, index: number) => (
                <tr key={item.id}>
                  <td><div>{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm') : '-'}</div></td>
                  <td><div>{item.ip}</div></td>
                  <td><div>{item.browserAgent}</div></td>
                  {/* <td><div></div></td> */}
                  <td><div>{item.user.fullName}</div></td>
                  <td><div>{item.success ? 'SUCESSO' : 'ERRO'}</div></td>
                </tr>
              ))}
            </tbody>
          </table>

          {currentPage && filterList?.length ?
            <div className='flex justify-end items-center gap-10'>
              <div className='table-pagination'>
                <IconButton 
                  size="small" aria-label="primeira pagina" component="label" 
                  onClick={() => handlePagination(1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage <= 1}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
                <IconButton 
                  size="small" aria-label="pagina anterior" component="label" 
                  onClick={() => handlePagination(currentPage - 1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage <= 1}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                {paginacao?.map(page => (
                  <Fragment key={page.toString()}>
                    {page === currentPage ? <div className='table-pagination_number active'>{page.toString()}</div> : 
                    <IconButton 
                      className='table-pagination_number'
                      size="small" aria-label="Próxima pagina" component="label" 
                      onClick={() => handlePagination(page, colunaBusca + search, pageSize )}
                    >
                      {page.toString()}
                    </IconButton>
                   }
                  </Fragment>
                ))}
                <IconButton 
                  size="small" aria-label="Próxima pagina" component="label" 
                  onClick={() => handlePagination(currentPage + 1, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage >= totalPages}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
                <IconButton 
                  size="small" aria-label="ultima pagina" component="label" 
                  onClick={() => handlePagination(totalPages, colunaBusca + search, pageSize )}
                  disabled={promiseInProgress || currentPage >= totalPages}
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </div>
              <Select
                style={{width: '100px'}}
                fullWidth
                size="small"
                value={pageSize || ''}
                disabled={promiseInProgress}
                onChange={e =>
                  handlePagination(1, colunaBusca + search, e.target.value )
                }
              >
                <MenuItem value="10">10 Itens</MenuItem>
                <MenuItem value="20">20 Itens</MenuItem>
                <MenuItem value="50">50 Itens</MenuItem>
                <MenuItem value="100">100 Itens</MenuItem>
              </Select>
            </div> : ''
          }
        </div>
      </div>

      {/* menu opções busca */}
      <Menu
        anchorEl={anchorElBusca}
        open={openBusca}
        onClose={handleCloseBusca}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBusca(e.target.value)}
                value={colunaBusca}
              >
                <FormControlLabel value="ip&&&" control={<Radio />} label="IP" />
                <FormControlLabel value="browserAgent&&&" control={<Radio />} label="Navegador" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>
    </>
  )
}

export default TabelaAutenticacao