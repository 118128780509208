import React, { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";

import {
  Grid,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Tooltip,
  ListItemText,
  Checkbox,
  FormControl,
  Select, backdropClasses
} from '@mui/material'
import KeyIcon from '@mui/icons-material/Key';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SendIcon from '@mui/icons-material/Send';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { PageTitle } from '../../components/PageElements'
import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IAplicacao, IClaim, ICargo, IUser, ILog, IBusca } from '../../interfaces/index'
import TabelaAcoes from '../../components/Logs/TabelaAcoes'
import TabelaAutenticacao from '../../components/Logs/TabelaAutenticacao'
import InfoUser from './InfoUser'
import services from '../../services/index.js'

import './style.scss'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DetalheUsuario() {
  const { id } = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [userIniciais, setUserIniciais] = useState("");
  const [userLogado, setUserLogado] = useState<IUser>({
    id: 0,
    name: '',
    phone: '',
    loginBlocked: false,
    updatePasswordOnLogin: false,
    username: '',
    email: '',
    company: '',
    internal: false,
    role: '',
    active: true,
    disabledByLoginTime: false,
    unblockDate: false,
    sraBlocked: false,
    skipSraCheck: false
  });

  const [usuario, setUsuario] = useState<IUser>({
    id: 0,
    name: '',
    phone: '',
    loginBlocked: false,
    updatePasswordOnLogin: false,
    username: '',
    email: '',
    company: '',
    internal: false,
    role: '',
    active: true,
    disabledByLoginTime: false,
    unblockDate: false,
    functionId: null,
    parentUserId: null,
    sraBlocked: false,
    skipSraCheck: false
  }) 

  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [currentPageAction, setCurrentPageAction] = useState(1)
  const [totalPagesAction, setTotalPagesAction] = useState(1)
  const [pageSizeAction, setPageSizeAction] = useState(20)

  const [logsLogin, setLogsLogin] = useState<ILog[] | []>([])
  const [logsAction, setLogsAction] = useState<ILog[] | []>([])

  const [cargos, setCargos] = useState<ICargo[] | []>([])
  const [aplicacoes, setAplicacoes] = useState<IAplicacao[] | []>([])
  const [claims, setClaims] = useState<IClaim[] | []>([])

  const [novaSenha, setNovaSenha] = useState<string>('')
  const [novaSenhaConfirm, setNovaSenhaConfirm] = useState<string>('')

  const [modalLogsLogin, setModalLogsLogin] = useState<boolean>(false)
  const [modalLogsAction, setModalLogsAction] = useState<boolean>(false)
  const [modalChangePassword, setModalChangePassword] = useState<boolean>(false)

  const handleOpenLogAction = () => {
    setModalLogsAction(true)
  }

  const handleOpenLogLogin = () => {
    setModalLogsLogin(true)
  }

  const resetPasswordUser = async () => {
    try {
      const res = await trackPromise(services.api.resetPassword(id))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao alterar senha'
        )
      }
      notify('Uma nova senha foi enviada para o email cadastrado', {
        variant: 'success'
      })
      setNovaSenha('')
      setNovaSenhaConfirm('')
      setModalChangePassword(false)
    } catch (error: any) {
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  const changePasswordUser = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    if(novaSenha.length < 8) {
      notify('Senha deve ter pelo menos 8 digitos', {
        variant: 'warning'
      })
      return
    }

    if(novaSenha !== novaSenhaConfirm) {
      notify('As senhas devem ser iguais', {
        variant: 'warning'
      })
      return
    }

    try {
      const res = await trackPromise(services.api.newPassword(id, novaSenha))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao alterar senha'
        )
      }
      notify('Senha alterada com sucesso', {
        variant: 'success'
      })
      setNovaSenha('')
      setNovaSenhaConfirm('')
      setModalChangePassword(false)
    } catch (error: any) {
      notify(error.message, {
        variant: 'error'
      })
    }
  }
  
  const saveUpdateUser = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    console.log('ob user ', usuario)

    if(usuario.cpf != undefined && usuario.cpf != ''){
      if (!cpfValidator.isValid(usuario.cpf.toString(), false)){
        notify('O CPF informado é inválido.', {
          variant: 'warning'
        })
        return
      }
    }

    if(usuario.phone != undefined && usuario.phone != ''){
      debugger
      if (usuario.phone.length < 11){
        notify('O telefone informado é inválido.', {
          variant: 'warning'
        })
        return
      }
    }


    //const roles = aplicacoes.filter((item: IAplicacao) => item.roleSelectedId).map((item: IAplicacao) => {return item.roleSelectedId})
    try {
      const res = await trackPromise(services.api.editUser(usuario))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao alterar usuário'
        )
      }
      notify('Usuário salvo com sucesso', {
        variant: 'success'
      })
    } catch (error: any) {
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  const getLogsLogin = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})
      paramns.filterBy = 'userId'
      paramns.filterValue = id
      
      const res = await trackPromise(services.api.getLogsLoginPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os Logs'
        )
      }

      setLogsLogin(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const unblockUser = async () => {
    try {
      const res = await trackPromise(services.api.unblockUser(usuario.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível desbloquear usuário'
        )
      }
      notify('Usuário desbloqueado com sucesso', { variant: 'success' })
      setUsuario(prevState => ({
        ...prevState,
        loginBlocked: false,
        functionId: null,
        parentUserId: null,
      }))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const getLogsLoginAction = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})
      paramns.filterBy = 'userId'
      paramns.filterValue = id

      const res = await trackPromise(services.api.getLogsActionPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os Logs'
        )
      }

      setLogsAction(res.data.data)
      setCurrentPageAction(res.data.pageIndex)
      setPageSizeAction(res.data.pageSize)
      setTotalPagesAction(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getLogsLogin({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage: number, coluna?: string, desc?: boolean) => {
    getLogsLogin({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePaginationAction = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getLogsLoginAction({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBuscaAction = useCallback(async (busca: string, sizePage: number, coluna?: string, desc?: boolean) => {
    getLogsLoginAction({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const changeRoleToUser2 = async (index: number, role: any, appId: number) => {
    const addRole = async (role:any) => {
      try {
        const res = await trackPromise(services.api.addRoleToUser(usuario.id, role.id))
        if (res.fail) {
          throw new Error(
            res.error || `Não foi possivel vincular esta permissão`
          )
        }
        setAplicacoes((prevState:any) => [
          ...prevState.slice(0, index),
          {...prevState[index], rolesSelected: [...prevState[index].rolesSelected, role] },
          ...prevState.slice(index + 1),
        ])
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    const removeRole = async (role:any) => {
      try {
        const res = await trackPromise(services.api.removeRoleToUser(usuario.id, role.id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível desvincular esta permissão'
          )
        }
        setAplicacoes((prevState:any) => [
          ...prevState.slice(0, index),
          {...prevState[index], rolesSelected: prevState[index].rolesSelected.filter((item: any) => item.id !== role.id) },
          ...prevState.slice(index + 1),
        ])
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    let indexLast = role.length - 1

    if (indexLast === 0) {
      const roleBase = claims.filter((item:any) => item.id === role[role.length - 1])
      addRole(roleBase[0])
    } else {
      const itensJaInclusos = role.filter((item: any) => item?.id === role[role.length - 1])

      if (itensJaInclusos?.length > 0) {
        removeRole(itensJaInclusos[0])
      } else {
        const roleBase = claims.filter((item:any) => item.id === role[role.length - 1])
        addRole(roleBase[0])
      }
    }

  }


  const changeRoleToUser = async (index: number, roleId: any) => {
    const prevValueRole = aplicacoes[index].roleSelectedId

    setAplicacoes((prevState:any) => [
      ...prevState.slice(0, index),
      {...prevState[index], roleSelectedId: roleId},
      ...prevState.slice(index + 1),
    ])

    if (roleId) {
      try {
        const res = await trackPromise(services.api.addRoleToUser(usuario.id, roleId))
        if (res.fail) {
          setAplicacoes((prevState:any) => [
            ...prevState.slice(0, index),
            {...prevState[index], roleSelectedId: prevValueRole},
            ...prevState.slice(index + 1),
          ])
          throw new Error(
            res.error || `Não foi possivel vincular esta permissão`
          )
        }
  
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.removeRoleToUser(usuario.id, prevValueRole))
        if (res.fail) {
          setAplicacoes((prevState:any) => [
            ...prevState.slice(0, index),
            {...prevState[index], roleSelectedId: prevValueRole},
            ...prevState.slice(index + 1),
          ])
          throw new Error(
            res.error || 'Não foi possível desvincular esta permissão'
          )
        }
  
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
  }

  const removeRoleToUser = async (index: number) => {
    const prevValueRole = aplicacoes[index].roleSelectedId
    if (promiseInProgress || !prevValueRole) return

    setAplicacoes((prevState:any) => [
      ...prevState.slice(0, index),
      {...prevState[index], roleSelectedId: ''},
      ...prevState.slice(index + 1),
    ])

    try {
      const res = await trackPromise(services.api.removeRoleToUser(usuario.id, prevValueRole))
      if (res.fail) {
        setAplicacoes((prevState:any) => [
          ...prevState.slice(0, index),
          {...prevState[index], roleSelectedId: prevValueRole},
          ...prevState.slice(index + 1),
        ])
        throw new Error(
          res.error || 'Não foi possível desvincular esta permissão'
        )
      }

    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    getLogsLogin()
    getLogsLoginAction()
  }, [getLogsLogin, getLogsLoginAction])

  useEffect(() => {
    const init = async () => {

      const [ resUser, resCargos, resAplications, resAppRoles ] = await trackPromise(
        Promise.all(
          [
            services.api.getUserById(id),
            services.api.getCargosAll(),
            services.api.getAplicatoinsAll(),
            services.api.getAplicationsRolesAll()
          ]
        )
      )

      if (resUser.fail) {
        throw new Error(
          resUser.error || 'Erro ao carregar dados do usuário'
        )
      }

      if (resCargos.fail) {
        throw new Error(
          resCargos.error || 'Erro ao carregar cargos'
        )
      }

      if (resAplications.fail) {
        throw new Error(
          resAplications.error || 'Não foi possível consultar os apps'
        )
      }

      if (resAppRoles.fail) {
        throw new Error(
          resAppRoles.error || 'Não foi possível consultar as claims'
        )
      }

      let aplicacoesMap = resAplications.data.map((item: IAplicacao) => {
        item.roleSelectedId = ''
        item.rolesSelected = []
        return item
      })

      let dataUser = resUser.data

      dataUser.roles?.forEach((userRoleItem: any) => {
        aplicacoesMap.forEach((appRoleItem: any) => {
          if(appRoleItem.id === userRoleItem.role.appId) {
            appRoleItem.roleSelectedId = userRoleItem.role.id
            appRoleItem.rolesSelected.push(userRoleItem.role)
          }
        });
      });
      dataUser.functionId = null
      dataUser.parentUserId = null

      setUsuario(dataUser)
      setCargos(resCargos.data)
      setClaims(resAppRoles.data)
      setAplicacoes(aplicacoesMap)

    }

    if (id) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const userObj: any = Cookies.get("TradeTalentosUserPAT");
    // const myDecodedToken = decodeToken(userObj)
    const myDecodedToken = userObj && JSON.parse(userObj);
    if (myDecodedToken?.user) {
      const userName = myDecodedToken.user.fullName.split(" ");
      const initials = `${userName[0] ? userName[0][0] : ""}${
        userName[1] ? userName[1][0] : ""
      }`;
      setUserIniciais(initials ? initials.toUpperCase() : "");
      setUserLogado(myDecodedToken.user)
    }
  }, []);

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div className='flex w-full justify-between items-center'>
            <div>Gerenciamento de Usuários</div>
            <Tooltip title={userLogado?.fullName}>
              <div className='user-container-icon'>{userIniciais}</div>
            </Tooltip>
          </div>
        </PageTitle>
        
        <form onSubmit={saveUpdateUser} autoComplete="off">
          <Grid container justifyContent="center" spacing={{ xs: 3 }}>
            <Grid item md={10} xs={12}>
              <div className='panel'>
                <div className='panel-header'>
                  <div>
                    <div>Dados do Usuário</div>
                    {usuario.loginBlocked ? <span style={{cursor: 'pointer'}} onClick={unblockUser} >Desbloquear usuário</span> : ''}
                  </div>
                </div>
                <div className='panel-body'>
                  <InfoUser user={usuario} setUser={setUsuario} cargos={cargos} newUser={false} />
                </div>
              </div>
            </Grid>

            <Grid item md={10} xs={12}>
              <Grid container justifyContent="center" spacing={{ xs: 3 }}>
                <Grid item md={8} xs={12}>
                  <div className='panel'>
                    <div className='panel-header'>
                      <div>
                        <div>Permissões Concedidas</div>
                      </div>
                    </div>
                    <div className='panel-body'>
                      <Grid container spacing={{ xs: 3 }}>
                        {aplicacoes.map((app: IAplicacao, index: number) => (
                          <Grid item lg={12} md={12} xs={12} key={app.id}>

                            <div className='mb-10 flex items-center gap-6'>
                              {userRoles.includes('user_update') ? 
                                <Tooltip title="Click para limpar a permissão">
                                  <ToggleOnIcon onClick={() => removeRoleToUser(index)} 
                                  />
                                </Tooltip>
                                : ''
                              } <span className='bold'>{app.name}</span>
                            </div> 
                              
                            <FormControl>
                              <InputLabel id="demo-simple-select-autowidth-label">Nome do perfil</InputLabel>
                              {/* <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                fullWidth
                                size="small"
                                label="Nome do perfil"
                                value={app.roleSelectedId}
                                disabled={promiseInProgress || !userRoles.includes('user_update')}
                                onChange={(e) => changeRoleToUser(index, e.target.value)}
                              >
                                <MenuItem value="" >
                                  Selecione
                                </MenuItem>
                                {claims.filter((item: IClaim) => item.appId === app.id).map((hole: IClaim) => (
                                  <MenuItem value={hole.id} key={hole.id} >
                                    {hole.name}
                                  </MenuItem>
                                ))}
                              </Select> */}
                              <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                fullWidth
                                multiple
                                size="small"
                                label="Nome do perfil"
                                disabled={promiseInProgress || !userRoles.includes('user_update')}
                                value={app.rolesSelected}
                                onChange={(e) => changeRoleToUser2(index, e.target.value, app.id)}
                                renderValue={(selected) => selected.map(item => (item.name)).join(', ')}
                                MenuProps={MenuProps}
                              >
                                {claims.filter((item: IClaim) => item.appId === app.id).map((hole: IClaim) => (
                                  <MenuItem value={hole.id} key={hole.id}>
                                    <Checkbox 
                                      checked={app?.rolesSelected ? app?.rolesSelected?.filter(item => item.id === hole.id).length > 0 : false} 
                                    />
                                    <ListItemText primary={hole.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </div>
                </Grid>

                <Grid item md={4} xs={12}>
                  <div className='panel'>
                    <div className='panel-header'>
                      <div>
                        <div>Ações</div>
                      </div>
                    </div>
                    <div className='panel-body'>
                      <div className='flex justify-center gap-10'>
                        {userRoles.includes("user_set_password") &&
                          <Button
                            style={{width: '180px'}}
                            variant="contained"
                            className="btn-main"
                            type="button"
                            id="btn_login_submit"
                            onClick={() => setModalChangePassword(true)}
                            disabled={promiseInProgress}
                            startIcon={<KeyIcon />}
                          >
                            ALTERAR SENHA
                          </Button>
                        }
                        {userRoles.includes("log_login_read") &&
                          <Button
                            style={{width: '180px'}}
                            variant="contained"
                            className="btn-main"
                            // color="warning"
                            type="button"
                            id="btn_login_submit"
                            onClick={handleOpenLogLogin}
                            disabled={promiseInProgress}
                            startIcon={<ClearAllIcon />}
                          >
                            LOGS DE LOGIN
                          </Button>
                        }
                        {userRoles.includes("log_action_read") &&
                          <Button
                            style={{width: '180px'}}
                            variant="contained"
                            className="btn-main"
                            // color="warning"
                            type="button"
                            id="btn_login_submit"
                            onClick={handleOpenLogAction}
                            disabled={promiseInProgress}
                            startIcon={<ClearAllIcon />}
                          >
                            LOGS DE AÇÕES
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>

      {/* modal logs action */}
      <Modal
        fullWidth
        open={modalLogsAction}
        close={() => setModalLogsAction(false)}
        titulo={'Registros de ações'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <TabelaAcoes 
              mainList={logsAction} 
              handlePagination={handlePaginationAction}
              handleBusca={handleBuscaAction}
              currentPage={currentPageAction}
              totalPages={totalPagesAction}
              pageSize={pageSizeAction}
              showLinkUser={false}
            />
          </Grid>
        </Grid>
      </Modal>

      {/* modal logs login */}
      <Modal
        fullWidth
        open={modalLogsLogin}
        close={() => setModalLogsLogin(false)}
        titulo={'Registros de Login'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <TabelaAutenticacao 
              mainList={logsLogin} 
              handlePagination={handlePagination}
              handleBusca={handleBusca}
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
            />
          </Grid>
        </Grid>
      </Modal>

      {/* modal change password */}
      <Modal
        size='sm'
        open={modalChangePassword}
        close={() => setModalChangePassword(false)}
        titulo={'Alteração de senha'}
      >
        <form onSubmit={changePasswordUser} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <div className='flex gap-8 items-center justify-center flex-nowrap'>
                <ErrorOutlineIcon style={{color: 'red'}} />
                <div>Evite alterar a senha do usuário manualmente. Se possível, utilize o botão abaixo para enviar uma senha aleatória para o e-mail de cadastro.</div>
              </div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Digite a senha"
                size='small'
                type='password'
                inputProps={{ maxLength: 400 }}
                value={novaSenha}
                onChange={e => 
                  setNovaSenha(e.target.value)
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Digite a senha novamente"
                size='small'
                type='password'
                inputProps={{ maxLength: 400 }}
                value={novaSenhaConfirm}
                onChange={e => 
                  setNovaSenhaConfirm(e.target.value)
                }
              />
            </Grid>
            
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
                startIcon={<SendIcon />}
                onClick={resetPasswordUser}
              >
                Enviar Senha Aleatória no e-mail
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="inherit"
                onClick={() => setModalLogsAction(false)}
                startIcon={<KeyIcon />}
              >
                Redefinir Senha
              </Button>
              
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  )
}

export default DetalheUsuario
