import React, { useState, useEffect, useCallback } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useNavigate } from "react-router-dom";
import { cpf as cpfValidator } from 'cpf-cnpj-validator';

import {
  Grid,
  IconButton,
  Button
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';

import InfoUser from './InfoUser'
import { PageTitle } from '../../components/PageElements'
import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IUser, IBusca, ICargo } from '../../interfaces/index'
import TabelaUsuarios from './TabelaUsuarios'
import services from '../../services/index.js'

import './style.scss'
import { cpf } from '../../services/mask'

function Usuarios() {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [modalUsuario, setModalUsuario] = useState(false)
  
  const [usuarios, setUsuarios] = useState<IUser[] | [] | null | undefined>(null)
  const [usuario, setUsuario] = useState<IUser>({
    id: 0,
    name: '',
    phone: '',
    loginBlocked: false,
    updatePasswordOnLogin: false,
    username: '',
    email: '',
    company: '',
    internal: false,
    role: '',
    active: true,
    disabledByLoginTime: false,
    unblockDate: false,
    sraBlocked: false,
    skipSraCheck: false
  }) 

  const [cargos, setCargos] = useState<ICargo[] | []>([])
  
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const getUsers = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})

      const res = await trackPromise(services.api.getUsersAll(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      
      setUsuarios(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getUsers({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getUsers({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const handleNewUser = useCallback(async () => {
    setUsuario(
      {
        id: 0,
        name: '',
        phone: '',
        loginBlocked: false,
        updatePasswordOnLogin: false,
        username: '',
        email: '',
        company: '',
        internal: false,
        role: '',
        active: true,
        disabledByLoginTime: false,
        unblockDate: false,
        sraBlocked: false,
        skipSraCheck: false
      }
    )
    setModalUsuario(true)
  }, [])

  const handleEditUser = useCallback(async (user:IUser) => {
    navigate(`/app/users/user/${user.id}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveUpdateUser = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (usuario.password !== usuario.passwordConfirm) {
      notify('Os campos de senha devem ser iguais', {
        variant: 'warning'
      })
      return
    }

    if(usuario.cpf != undefined && usuario.cpf != ''){
      if (!cpfValidator.isValid(usuario.cpf.toString(), false)){
        notify('O CPF informado é inválido.', {
          variant: 'warning'
        })
        return
      }
    }


    try {
      const res = await trackPromise(services.api.criarUser(usuario))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao criar usuário'
        )
      }
      notify('Usuário criado com sucesso', {
        variant: 'success'
      })
      navigate(`/app/users/user/${res.data.id}`)
    } catch (error: any) {
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const res = await trackPromise(services.api.getCargosAll())
        if (res.fail) {
          throw new Error(
            res.error || 'Erro ao carregar cargos'
          )
        }
        setCargos(res.data)
      } catch (error: any) {
        notify(error.message, {
          variant: 'error'
        })
      }
    }

    getUsers()
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers])

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div className='flex w-full justify-between items-center'>
            <div>Gerenciamento de Usuários</div>
            {userRoles.includes('user_create') && 
              <Button
                variant="contained"
                className="btn-main"
                size="small"
                type="button"
                onClick={(e) => handleNewUser()}
                disabled={promiseInProgress}
                startIcon={<AddCircleIcon style={{color:'white'}} fontSize='small' />}
              >
                NOVO
              </Button>
            }
          </div>
        </PageTitle>
        
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div className='panel'>
              <div className='panel-body'>
                <TabelaUsuarios 
                  mainList={usuarios} 
                  editRegistro={handleEditUser}
                  handlePagination={handlePagination}
                  handleBusca={handleBusca}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                />
              </div>
            </div>
          </Grid>
        </Grid>

      </div>

      {/* modal usuário */}
      <Modal
        fullWidth
        open={modalUsuario}
        close={() => setModalUsuario(false)}
        titulo={'Novo usuário'}
      >
        <div className='pt-20 pb-20'>
          <form onSubmit={saveUpdateUser} autoComplete="off">
            <InfoUser user={usuario} setUser={setUsuario} cargos={cargos} newUser={true} />
          </form>
        </div>
      </Modal>

      
    </div>
  )
}

export default Usuarios
