import { useState, useEffect } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useParams } from 'react-router-dom';

import {
  Grid,
  TextField,
  Button
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { IAplicacao } from '../../interfaces/index'
import TabelaPerfis from './TabelaPerfis'
import TabelaClaims from './TabelaClaims'
import TabelaAmbientes from './TabelaAmbientes'
import Tabs from '../../components/Tabs'

import services from '../../services/index.js'
import imgDefault from '../../assets/img/default_picture.png'

import './style.scss'

function AppsPageEdit() {
  const { id } = useParams();
  const { promiseInProgress } = usePromiseTracker();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [aplication, setAplication] = useState<IAplicacao>(
    {
      id: 0,
    }
  )
  const [abaActive, setAbaActive] = useState(null)
  const [tabsList, settTabsList] = useState<any[] | []>([])

  const saveApp = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    if (!aplication.imageUrl?.startsWith('http')) {
      notify('Insira uma url valida', { variant: 'warning' })
      return
    }

    try {
      const res = await trackPromise(services.api.editAplicatoin(
        aplication.id,
        aplication.name,
        aplication.description,
        aplication.imageUrl,
        aplication.active
      ))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível salvar Cargo'
        )
      }
      notify('App salvo com sucesso', { variant: 'success' })
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }

  useEffect(() => {
    const getApps = async () => {
      try {
        const res = await trackPromise(services.api.getAplicatoinsById(id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Usuários'
          )
        }
        setAplication(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    if (id) {
      getApps()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    let tabset: any = []
    tabset.push({id: 0, label: 'Dados'})
    if (userRoles) {
      if (userRoles.includes('app_role_read')) {
        tabset.push({id: 1, label: 'Perfis'})
      }
      if (userRoles.includes("app_claim_read")) {
        tabset.push({id: 2, label: 'Claims'})
      }
      if (userRoles.includes("app_env_read")) {
        tabset.push({id: 3, label: 'Ambientes'})
      }
    }
    if(tabset?.length) {
      settTabsList(tabset)
      setAbaActive(tabset[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div>Gerenciamento de Apps - {aplication.name}</div>
        </PageTitle>

        <Grid container justifyContent="center">
          <Grid item md={10} xs={12}>
            <Tabs tabs={tabsList} active={abaActive} onChange={setAbaActive} />
          </Grid>
          <Grid item md={10} xs={12}>
            {abaActive === 0 && 
              <div className='panel'>
                <div className='panel-header'>
                  <div>
                    <div>Dados do App</div>
                  </div>
                </div>
                <div className='panel-body'>
                  <form onSubmit={saveApp} autoComplete="off">
                    <Grid container spacing={{ xs: 3 }}>
                      <Grid item lg={8} md={12} sm={8} xs={12}>
                        <Grid container spacing={{ xs: 3 }}>
                          <Grid item md={6} sm={12} xs={12}>
                            <TextField
                              fullWidth
                              required
                              size='small'
                              label="Nome"
                              inputProps={{ maxLength: 120 }}
                              value={aplication.name ? aplication.name : ''}
                              onChange={e => 
                                setAplication(prevState => ({
                                  ...prevState,
                                  name: e.target.value
                                }))
                              }
                            />
                          </Grid>
                          <Grid item md={6} sm={12} xs={12}>
                            <TextField
                              fullWidth
                              required
                              size='small'
                              label="Descrição"
                              inputProps={{ maxLength: 120 }}
                              value={aplication.description ? aplication.description : ''}
                              onChange={e => 
                                setAplication(prevState => ({
                                  ...prevState,
                                  description: e.target.value
                                }))
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              required
                              size='small'
                              label="url da imagem"
                              inputProps={{ maxLength: 120 }}
                              value={aplication.imageUrl ? aplication.imageUrl : ''}
                              onChange={e => 
                                setAplication(prevState => ({
                                  ...prevState,
                                  imageUrl: e.target.value
                                }))
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      
                      <Grid item lg={4} md={8} sm={4} xs={12}>
                        {aplication.imageUrl && 
                          <div className='container-img'>
                            <img src={aplication.imageUrl} alt={'imagem aplicação'} 
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = imgDefault;
                              }} 
                            />
                          </div>
                        }
                      </Grid>
                      {userRoles.includes('app_update') && 
                        <Grid item xs={12} className='flex justify-end'>
                          <Button
                            variant="contained"
                            className="btn-main"
                            type="submit"
                            disabled={promiseInProgress}
                            startIcon={<SaveIcon fontSize="inherit" />}
                          >
                            SALVAR
                          </Button>
                        </Grid>
                      }
                      
                    </Grid>
                  </form>
                </div>
              </div>
            }
            {abaActive === 1 && 
              <TabelaPerfis
                aplicationId={aplication.id}
              />
            }
            {abaActive === 2 && 
              <TabelaClaims
                aplicationId={aplication.id}
              />
            }
            {abaActive === 3 && 
              <TabelaAmbientes
                aplicationId={aplication.id}
              />
            }
            
          </Grid>
        </Grid>
        
      </div>
      
    </div>
  )
}

export default AppsPageEdit
