import HeaderLateral from '../components/HeaderLateral'


import './style.scss'

const LateralHeader = props => {
  return (
    <>
      <HeaderLateral />
      
      <div id="wrapper">
        <div className="page-container">{props.children}</div>
      </div>
    </>
  )
}

export default LateralHeader
