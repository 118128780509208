import { appConfig } from '../../configs/env-config.js'

import * as apiConfig from './apiConfig.js'

const apiRoute = appConfig.REACT_APP_UrlApi
const pageSizeDefault = 20;

/////////////////////////////////////////////////////////////////////
// login
/////////////////////////////////////////////////////////////////////

export const login = async (login, password) => {
  const data = {
    username: login,
    rawPassword: password
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth`, 'POST', data)
}

export const loginWithMfa = async (login, password, mfaType, mfaCode) => {
  const data = {
    username: login,
    rawPassword: password,
    mfaType: mfaType,
    mfaCode: mfaCode
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/mfa`, 'POST', data)
}

export const getTotpConfiguration = async (login, password) => {
  const data = {
    username: login,
    rawPassword: password,
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/mfa/totp/requestconfig`, 'POST', data)
}

export const finishTotpConfiguration = async (login, password, key, code) => {
  const data = {
    username: login,
    rawPassword: password,
    key: key,
    totpCode: code
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/mfa/totp/setconfig`, 'POST', data)
}

export const requestMfaEmailCode = async (username) => {
  const data = {
    username: username
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/mfa/requestemail`, 'POST', data)
}

export const recoveryRequest = async (username) => {
  const data = {
    username
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/startrecovery`, 'POST', data)
}

export const changePasswordByEmail = async (email, secret, secretId, newPassword) => {
  const data = {
    username: email,
    secret: secret,
    secretId: secretId,
    newPassword: newPassword
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/recoverypassword`, 'POST', data)
}

export const changePasswordExpired = async (email, newPassword, oldSenha) => {
  const data = {
    username: email,
    rawPassword: oldSenha,
    newPassword: newPassword
  }
  return await apiConfig.sendWithAxios(`${apiRoute}auth/resetpassword`, 'POST', data)
}


/////////////////////////////////////////////////////////////////////
// user
/////////////////////////////////////////////////////////////////////

export const getUserById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/id/${id}`,
    'GET'
  )
}

export const getUsersAll = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getUserAssignable = async (functionId, userId) => {
  if (functionId &&  userId) {
    return await apiConfig.sendWithAxios(
      `${apiRoute}users/all/assignable/${userId}/${functionId}`,
      'GET'
    )
  } else {
    return await apiConfig.sendWithAxios(
      `${apiRoute}users/all/assignable/newuser/${functionId}`,
      'GET'
    )
  }
}

export const resetPassword = async (userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/resetpassword/${userId}`,
    'POST'
  )
}

export const unblockUser = async (userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/unblock/${userId}`,
    'PUT'
  )
}

export const unblockUserOnSra = async (userId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/sraworker/unblock/${userId}`,
    'PUT'
  )
}

export const newPassword = async (userId, password) => {
  const data = {password: password}
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/newpassword/${userId}`,
    'PUT',
    data
  )
}

export const addRoleToUser = async (userId, roleId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/roles/add/${userId}/${roleId}`,
    'PUT'
  )
}

export const removeRoleToUser = async (userId, roleId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/roles/remove/${userId}/${roleId}`,
    'DELETE'
  )
}

export const editUser = async (user) => {
  let data = {
    id: user.id,
    email: user.email,
    username: user.username,
    internal: user.internal,
    fullName: user.fullName,
    company: user.company,
    active: user.active,
    expirationDays: user.expirationDays,
    updatePasswordOnLogin: user.updatePasswordOnLogin,
    parentUserId: user.parentUserId ? user.parentUserId : null,
    functionId: user.functionId ? user.functionId : null,
    cpf: user.cpf ? user.cpf : null,
    phone: user.phone ? user.phone : null,
    portalRole: user.portalRole,
    mfaEnabled: user.mfaEnabled,
    disabledByLoginTime: user.disabledByLoginTime,
    skipSraCheck: user.skipSraCheck
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users`,
    'PUT',
    data
  )
}

export const criarUser = async (user) => {
  const data = {
    email: user.email,
    username: user.username,
    internal: user.internal,
    fullName: user.fullName,
    company: user.company,
    active: user.active,
    expirationDays: user.expirationDays,
    updatePasswordOnLogin: user.updatePasswordOnLogin,
    parentUserId: user.parentUserId ? user.parentUserId : null,
    functionId: user.functionId ? user.functionId : null,
    portalRole: user.portalRole,
    cpf: user.cpf ? user.cpf : null,
    phone: user.phone ? user.phone : null,
    disabledByLoginTime: user.disabledByLoginTime,
    mfaEnabled: user.mfaEnabled,
    password: user.password,
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users`,
    'POST',
    data
  )
}

/////////////////////////////////////////////////////////////////////
// cargos
/////////////////////////////////////////////////////////////////////

export const getCargosAllPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}functions/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getCargosAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}functions/all`,
    'GET'
  )
}

export const criarCargo = async (name, descrition, parentFunctionId) => {
  const data = {
    name: name,
    description: descrition,
    parentFunctionId: parentFunctionId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}functions/`,
    'POST',
    data
  )
}

export const editCargo = async (name, descrition, parentFunctionId, id) => {
  const data = {
    id: id,
    name: name,
    description: descrition,
    parentFunctionId: parentFunctionId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}functions`,
    'PUT',
    data
  )
}

export const excluirCargo = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}functions/id/${id}`,
    'DELETE'
  )
}

/////////////////////////////////////////////////////////////////////
// logs
/////////////////////////////////////////////////////////////////////


export const getLogsLoginPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}logs/login/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getLogsActionPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}logs/action/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getLogsActionById = async (logId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}logs/action/id/${logId}`,
    'GET'
  )
}


/////////////////////////////////////////////////////////////////////
// Aplications
/////////////////////////////////////////////////////////////////////

export const getAplicatoinsAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}apps/all`,
    'GET'
  )
}

export const getAplicatoinsById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}apps/id/${id}`,
    'GET'
  )
}

export const deleteAplicatoinsById = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}apps/id/${id}`,
    'DELETE'
  )
}

export const createAplicatoin = async (name, descrition, imageUrl, active) => {
  const data = {
    name: name,
    description: descrition,
    imageUrl: imageUrl,
    active: active
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}apps`,
    'POST',
    data
  )
}

export const editAplicatoin = async (id, name, descrition, imageUrl, active) => {
  const data = {
    id: id,
    name: name,
    description: descrition,
    imageUrl: imageUrl,
    active: active
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}apps`,
    'PUT',
    data
  )
}


/////////////////////////////////////////////////////////////////////
// Aplications Claims
/////////////////////////////////////////////////////////////////////

export const getClaimAllPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getClaimAllByAppId = async (appId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims/all/appid/${appId}`,
    'GET'
  )
}


export const getClaimAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims/all`,
    'GET'
  )
}

export const criarClaim = async (value, descrition, appId) => {
  const data = {
    value: value,
    description: descrition,
    appId: appId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims/`,
    'POST',
    data
  )
}

export const editClaim = async (value, descrition, appId, id) => {
  const data = {
    id: id,
    value: value,
    description: descrition,
    appId: appId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims`,
    'PUT',
    data
  )
}

export const excluirClaim = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}appclaims/id/${id}`,
    'DELETE'
  )
}

/////////////////////////////////////////////////////////////////////
// Aplications environments (ambientes)
/////////////////////////////////////////////////////////////////////

export const getEnvironmentsAllById = async (appId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}appEnvironments/all/${appId}`,
    'GET'
  )
}

export const criarEnvironment = async (name, jwt, url, appId) => {
  const data = {
    name: name,
    jwtToken: jwt,
    baseUrl: url,
    appId: appId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}appEnvironments`,
    'POST',
    data
  )
}

export const editarEnvironment = async (name, jwt, url, appId, id) => {
  const data = {
    name: name,
    jwtToken: jwt,
    baseUrl: url,
    appId: appId,
    id: id
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}appEnvironments`,
    'PUT',
    data
  )
}

export const deleteEnvironment = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}appEnvironments/id/${id}`,
    'DELETE'
  )
}

/////////////////////////////////////////////////////////////////////
// Aplications Roles
/////////////////////////////////////////////////////////////////////

export const getAplicationsRolesAll = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/all`,
    'GET'
  )
}

export const getPerfilAllPaged = async (params, page = 1, pageSize = pageSizeDefault) => {
  const {search, orderByColuna, desc, filterBy, filterValue} = params
  let query = `${page}/${pageSize}`
  let queryComplement = ''
  if (search) {
    queryComplement += `${queryComplement ? '&' : '?'}${search}`
  }
  if (orderByColuna && !desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderBy=${orderByColuna}`
  }
  if (orderByColuna && desc) {
    queryComplement += `${queryComplement ? '&' : '?'}orderByDesc=${orderByColuna}`
  }
  if (filterBy && filterValue) {
    queryComplement += `${queryComplement ? '&' : '?'}${filterBy}=${filterValue}`
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/all/paged/${query}${queryComplement}`,
    'GET'
  )
}

export const getPerfilAllById = async (appId) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/id/${appId}`,
    'GET'
  )
}

export const criarPerfil = async (name, descrition, appId) => {
  const data = {
    name: name,
    description: descrition,
    appId: appId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/`,
    'POST',
    data
  )
}

export const vincularPeriflComClaim = async (roleId, claimsId) => {
  // claimsid [3,4]
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/roleclaims/add/${roleId}`,
    'PUT',
    claimsId
  )
}

export const desvincularPeriflComClaim = async (roleId, claimsId) => {
  // claimsid [3,4]
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/roleclaims/remove/${roleId}`,
    'DELETE',
    claimsId
  )
}

export const editPerfil = async (name, descrition, appId, id) => {
  const data = {
    id: id,
    name: name,
    description: descrition,
    appId: appId
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles`,
    'PUT',
    data
  )
}

export const excluirPerfil = async (id) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}approles/id/${id}`,
    'DELETE'
  )
}



/////////////////////////////////////////////////////////////////////
// 
/////////////////////////////////////////////////////////////////////

export const editUsuarioMe = async (user) => {
  let data = {
    id: user.id,
    email: user.email,
    phone: user.phone,
    password: user.password
  }
  data.active = user.active
  if (user.phone) {
    data.phone = user.phone
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/me/updateinfo`,
    'PUT',
    data
  )
}
export const editSenha = async (password) => {
  let data = {
    password,
  }
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/me/newpassword`,
    'PUT',
    data
  )
}
export const isadmin = async (idEmpresa) => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}environments/${idEmpresa}/isadmin/me`,
    'GET'
  )
}
export const getUserMe = async () => {
  return await apiConfig.sendWithAxios(
    `${apiRoute}users/me`,
    'GET'
  )
}