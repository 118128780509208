import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { debounce } from 'lodash'
import moment from 'moment'

import {
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  Select,
  Button,
  FormControl,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services'

import { IBusca, IClaim } from '../../interfaces/index'
import { useAuth } from '../../hooks/useAuth'

interface ISectionClaims {
  aplicationId?: number
}

enum UserMode {
  novo,
  edicao
}

function TabelaClaims(props: ISectionClaims) {
  const { aplicationId } = props;
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [userMode, setUserMode] = useState<UserMode>(UserMode.novo)
  const [modalClaim, setModalClaim] = useState(false)
  const [modalRemoverClaim, setModalRemoverClaim] = useState<boolean>(false)
  const [claims, setClaims] = useState<IClaim[] | [] | null | undefined>(null)
  const [claimActive, setClaimActive] = useState<IClaim>(
    {
      value: "",
      id: 0
    }
  )
   
  const [anchorElBusca, setAnchorElBusca] = useState<null | HTMLElement>(null);
  const openBusca = Boolean(anchorElBusca);
  const handleClickBusca = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBusca(event.currentTarget);
  };
  const handleCloseBusca = () => {
    setAnchorElBusca(null);
  };

  const newRegister = useCallback(async () => {
    setClaimActive(
      {
        id: 0,
        value: "",
        description: "",
        appId: aplicationId,
      }
    )
    setUserMode(UserMode.novo)
    setModalClaim(true)
  }, [aplicationId])

  const [filterList, setFilterList] = useState<any>(null)
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('valeu&&&')
  const [paginacao, setPaginacao] = useState<number[] | []>([])

  const ativaOrdenacao = true

  const getClaims = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})
      paramns.filterBy = 'appId'
      paramns.filterValue = aplicationId
      
      const res = await trackPromise(services.api.getClaimAllPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar as Claims'
        )
      }
      
      setClaims(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getClaims({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getClaims({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const editRegistro = useCallback(async (claimItem:IClaim) => {
    setClaimActive(
      {
        id: claimItem.id,
        value: claimItem.value,
        description: claimItem.description,
        appId: claimItem.appId
      }
    )
    setUserMode(UserMode.edicao)
    setModalClaim(true)
  }, [])

  const handleRemoverRegistro = async (claimItem:IClaim) => {
    setClaimActive(
      {
        id: claimItem.id,
        value: claimItem.value
      }
    )
    setModalRemoverClaim(true)
  }

  const saveUpdateClaim = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    function reloadNewUser() {
      setModalClaim(false)
      getClaims()
    }

    async function criarClaim() {
      try {
        const res = await trackPromise(services.api.criarClaim(claimActive.value, claimActive.description, claimActive.appId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível salvar a Claim'
          )
        }
        notify('Claim salva com sucesso', { variant: 'success' })
        reloadNewUser()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    async function editarClaim() {
      try {
        const res = await trackPromise(services.api.editClaim(claimActive.value, claimActive.description, claimActive.appId, claimActive.id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar a Claim'
          )
        }
        notify('Claim editada com sucesso', { variant: 'success' })

        setClaims(
          claims?.map(item => 
            item.id === claimActive.id 
            ? {...item, 
              value : claimActive.value,
              description: claimActive.description
            }
            : item 
        ))
        setModalClaim(false)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (claimActive.id) {
      // edição
      editarClaim()
    } else {
      // novo
      criarClaim()
    }
    
  }

  const excluirClaim = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    try {
      const res = await trackPromise(services.api.excluirClaim(claimActive.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível excluir a Claim'
        )
      }
      notify('Claim excluida com sucesso', { variant: 'success' })
      setModalRemoverClaim(false)
      setClaims(
        claims?.filter(item => item.id !== claimActive.id 
      ))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }

  /////////////////////////

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(colunaBusca + busca, pageSize)
  }, 800),  [colunaBusca, pageSize])

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(colunaBusca + search, pageSize, column, direction)
    } else {
      handleBusca(colunaBusca + search, pageSize, colunaAtiva, direction)
    }
  }

  useEffect(() => {
    setFilterList(claims?.map((item:IClaim) => {
      return item
    }))
  }, [claims])

  useEffect(() => {
    setPaginacao(services.utils.getPaginationItens(totalPages, currentPage))
  }, [totalPages, currentPage])

  /////////////////////////
  
  useEffect(() => {
    if(aplicationId) {
      getClaims()
    }
  }, [getClaims, aplicationId])

  return (
    <>
      <div className='panel'>
        <div className='panel-header'>
          <div className='flex justify-between items-center w-full'>
            <div>Claíms</div>
            {userRoles.includes('app_claim_create') &&
              <Button
                variant="contained"
                className="btn-main"
                size="small"
                type="button"
                onClick={(e) => newRegister()}
                disabled={promiseInProgress}
                startIcon={<AddCircleIcon style={{color:'white'}} fontSize='small' />}
              >
                NOVO
              </Button>
            }
          </div>
        </div>
        <div className='panel-body'>
          <div className='wrapper-page-content-interna'>
            <div className='flex items-center justify-between gap-10'>
              <div className='flex gap-10'></div>
              
              <div className='flex items-center gap-10'>
                <div className='flex items-center gap-6'>
                  <TextField
                    size='small'
                    className='w-200'
                    label='Pesquisa'
                    placeholder='Pesquisa'
                    inputProps={{ maxLength: 50 }}
                    value={search}
                    onChange={e => handleChangeBusca(e.target.value)}
                    disabled={promiseInProgress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                            aria-label="toggle password visibility"
                            onClick={(e) => handleClickBusca(e)}
                            edge="end"
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='divider' style={{opacity: 0}}></div>
            <div className='table-container'>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>
                        <span className='th-title'> Valor</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'value')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'value')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    <th>
                      <div>
                        <span className='th-title'>Descrição</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'description')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'description')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    <th>
                      <div>
                        <span className='th-title'>Data de adição</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'createAt')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'createAt')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    {(userRoles.includes("app_claim_update") || userRoles.includes("app_claim_delete")) && 
                      <th><div><span className='th-title'>Ações</span></div></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {!filterList && 
                    <tr><td colSpan={4}><div className='justify-center'>loading...</div></td></tr>
                  }
                  {filterList?.length === 0 && 
                    <tr><td colSpan={4}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                  }
                  {filterList?.map((item: IClaim, index: number) => (
                    <tr key={item.id}>
                      <td><div>{item.value}</div></td>
                      <td><div>{item.description}</div></td>
                      <td><div>{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm') : '-'}</div></td>
                      {(userRoles.includes("app_claim_update") || userRoles.includes("app_claim_delete")) && 
                        <td>
                          <div className='justify-end'>
                            {userRoles.includes("app_claim_update") &&
                              <IconButton  
                                size="small" aria-label="editar Claim" component="label" 
                                onClick={() => editRegistro(item)}
                                disabled={promiseInProgress}
                              >
                                <ModeEditOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            }
                            {userRoles.includes("app_claim_delete") &&
                              <IconButton  
                                size="small" aria-label="excluir Claim" component="label" 
                                onClick={() => handleRemoverRegistro(item)}
                                disabled={promiseInProgress}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            }
                          </div>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>

              {currentPage && filterList?.length ?
                <div className='flex justify-end items-center gap-10'>
                  <div className='table-pagination'>
                    <IconButton 
                      size="small" aria-label="primeira pagina" component="label" 
                      onClick={() => handlePagination(1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage <= 1}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </IconButton>
                    <IconButton 
                      size="small" aria-label="pagina anterior" component="label" 
                      onClick={() => handlePagination(currentPage - 1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage <= 1}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    {paginacao?.map(page => (
                      <Fragment key={page.toString()}>
                        {page === currentPage ? <div className='table-pagination_number active'>{page.toString()}</div> : 
                        <IconButton 
                          className='table-pagination_number'
                          size="small" aria-label="Próxima pagina" component="label" 
                          onClick={() => handlePagination(page, colunaBusca + search, pageSize )}
                        >
                          {page.toString()}
                        </IconButton>
                      }
                      </Fragment>
                    ))}
                    <IconButton 
                      size="small" aria-label="Próxima pagina" component="label" 
                      onClick={() => handlePagination(currentPage + 1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage >= totalPages}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton 
                      size="small" aria-label="ultima pagina" component="label" 
                      onClick={() => handlePagination(totalPages, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage >= totalPages}
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                  </div>
                  <Select
                    style={{width: '100px'}}
                    fullWidth
                    size="small"
                    value={pageSize || ''}
                    disabled={promiseInProgress}
                    onChange={e =>
                      handlePagination(1, colunaBusca + search, Number(e.target.value) )
                    }
                  >
                    <MenuItem value="10">10 Itens</MenuItem>
                    <MenuItem value="20">20 Itens</MenuItem>
                    <MenuItem value="50">50 Itens</MenuItem>
                    <MenuItem value="100">100 Itens</MenuItem>
                  </Select>
                </div> : ''
              }
            </div>
          </div>
        </div>
      </div>
      
      {/* menu opções busca */}
      <Menu
        anchorEl={anchorElBusca}
        open={openBusca}
        onClose={handleCloseBusca}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBusca(e.target.value)}
                value={colunaBusca}
              >
                <FormControlLabel value="value&&&" control={<Radio />} label="Valor" />
                <FormControlLabel value="description&&&" control={<Radio />} label="Descrição" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>

       {/* modal Claim novo/edit */}
       <Modal
        size='sm'
        open={modalClaim}
        close={() => setModalClaim(false)}
        titulo={userMode === UserMode.edicao ? 'Edição de Claim' : 'Nova Claim'}
      >
        <form onSubmit={saveUpdateClaim} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Value"
                inputProps={{ maxLength: 400 }}
                value={claimActive.value}
                onChange={e => 
                  setClaimActive(prevState => ({
                    ...prevState,
                    value: e.target.value.replace(/\s/g, '')
                  }))
                }
              />
            </Grid>
            
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Descrição"
                inputProps={{ maxLength: 400 }}
                value={claimActive.description}
                onChange={e => 
                  setClaimActive(prevState => ({
                    ...prevState,
                    description: e.target.value
                  }))
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalClaim(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal excluir claim */}
      <Modal
        size='sm'
        open={modalRemoverClaim}
        close={() => setModalRemoverClaim(false)}
        titulo={'Excluir Claim'}
      >
        <form onSubmit={excluirClaim} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <div className='flex gap-8 items-center justify-center flex-nowrap'>
                <ErrorOutlineIcon style={{color: 'red'}} />
                <div>Tem certeza que deseja excluir a claim <strong>{claimActive.value}</strong>.</div>
              </div>
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalRemoverClaim(false)}
              >
                cancelar
              </Button> 
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

    </>
  )
}

export default TabelaClaims
