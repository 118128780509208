import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { debounce } from 'lodash'
import moment from 'moment'

import {
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  Select,
  Button,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Paper,
  Radio,
  IconButton
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services'

import { IBusca, IPerfil, IClaim } from '../../interfaces/index'
import { useAuth } from '../../hooks/useAuth'

interface ISection {
  aplicationId?: number
}

enum UserMode {
  novo,
  edicao
}

function not(a: readonly IClaim[], b: readonly IClaim[]) {
  // return a.filter((value) => b.indexOf(value) === -1);
  return a.filter((value) => services.utils.findIndexInArray(value.id, b, 'id') === -1);
}

function intersection(a: readonly IClaim[], b: readonly IClaim[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function TabelaPerfis(props: ISection) {
  const { aplicationId } = props;
  
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [checked, setChecked] = React.useState<readonly IClaim[]>([]);
  const [left, setLeft] = React.useState<readonly IClaim[]>([]);
  const [right, setRight] = React.useState<readonly IClaim[]>([]);

  const [filterLeft, setFilterLeft] = React.useState<string>('');
  const [filterRight, setFilterRight] = React.useState<string>('');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: IClaim) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = async () => {
    const listClaims = leftChecked.map(item => item.id)
    //console.log(leftChecked, listClaims)
    
    try {
      const res = await trackPromise(services.api.vincularPeriflComClaim(perfilActive.id, listClaims))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular permissão'
        )
      }
      setFilterRight('')
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  };

  const handleCheckedLeft = async () => {
    const listClaims = rightChecked.map(item => item.id)
    //console.log(rightChecked, listClaims)

    try {
      const res = await trackPromise(services.api.desvincularPeriflComClaim(perfilActive.id, listClaims))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular permissão'
        )
      }
      setFilterLeft('')
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const filtrarLista = (value: string, side: string) => {
    if (side === 'left') {
      setFilterLeft(value)
    } else {
      setFilterRight(value)
    }
  } 

  const customList = (items: readonly IClaim[], titulo: string, side: string) => (
    <Paper variant="outlined" sx={{ width: 300, height: 400, overflow: 'auto' }}>
      <div style={{textAlign: 'center', fontSize: '12px', margin: '10px 0 8px 0px' }}>{titulo}</div>
      <div style={{margin:'0 20px 0 20px'}}>
        <TextField
          size='small'
          className='mb-10'
          label='Pesquisa'
          placeholder='Pesquisa'
          inputProps={{ maxLength: 50 }}
          value={side === 'left' ? filterLeft : filterRight}
          onChange={e => filtrarLista(e.target.value, side)}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton
          //         style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
          //         aria-label="toggle password visibility"
          //         onClick={(e) => handleClickBuscaUserDisponivel(e)}
          //         edge="end"
          //       >
          //         <FilterAltIcon />
          //       </IconButton>
          //     </InputAdornment>
          //   )
          // }}
        />
      </div>
      <List dense component="div" role="list">
        {items.length === 0 ? 
          <div className='flex flex-nowrap gap-8 justify-center items-center' 
            style={{fontSize: '14px', margin: '22px'}}
          >
            <ErrorOutlineIcon style={{color: 'red'}} /> 
            <span style={{maxWidth: 'fit-content'}}>Não ha {titulo}</span>
          </div> : ''
        }
        {items.filter((value: IClaim) => {
          const filterParam = side === 'left' ? filterLeft : filterRight
          if (!filterParam) return value
          if (value?.description?.toUpperCase().includes(filterParam.toUpperCase()) || value?.value?.toUpperCase().includes(filterParam.toUpperCase())) return value
          return null
        }).map((value: IClaim) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              className={`${checked.indexOf(value) !== -1 ? 'active' : ''}`}
              style={{flexDirection: 'column', alignItems: 'start'}}
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              {/* <ListItemIcon>
                <Checkbox
                  //style={{di}}
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon> */}
              <ListItemText id={labelId} primary={`${value.value}`} style={{margin: '0px', fontSize: '12px', lineBreak: 'anywhere'}} />
              <div style={{fontSize: '10px'}}>{value.description}</div>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );


  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const [userMode, setUserMode] = useState<UserMode>(UserMode.novo)
  const [modalPerfil, setModalPerfil] = useState(false)
  const [modalRemoverPerfil, setModalRemoverPerfil] = useState<boolean>(false)
  const [perfis, setPerfis] = useState<IPerfil[] | [] | null | undefined>(null)
  const [permissoesDiponiveisApp, setPermissoesDiponiveisApp] = useState<IClaim[] | []>([])
  const [perfilActive, setPerfilActive] = useState<IPerfil>(
    {
      name: "",
      id: 0
    }
  )

   
  const [anchorElBusca, setAnchorElBusca] = useState<null | HTMLElement>(null);
  const openBusca = Boolean(anchorElBusca);
  const handleClickBusca = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBusca(event.currentTarget);
  };
  const handleCloseBusca = () => {
    setAnchorElBusca(null);
  };

  const newRegister = useCallback(async () => {
    setPerfilActive(
      {
        id: 0,
        name: "",
        description: "",
        appId: aplicationId,
      }
    )
    setUserMode(UserMode.novo)
    setModalPerfil(true)
  }, [aplicationId])

  const [filterList, setFilterList] = useState<any>(null)
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('valeu&&&')
  const [paginacao, setPaginacao] = useState<number[] | []>([])

  const ativaOrdenacao = true

  const getRegistros = useCallback(async (objBusca?: IBusca) => {
    try {
      const pagina = objBusca?.page ? objBusca?.page : currentPage
      const termoBusca = objBusca?.busca ? objBusca?.busca : ''
      const orderBycolumn = objBusca?.filterColuna ? objBusca?.filterColuna : ''
      const desc = objBusca?.desc ? objBusca?.desc : ''
      const tamanhoPagina = objBusca?.pageSize ? objBusca?.pageSize : pageSize
      let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})
      paramns.filterBy = 'appId'
      paramns.filterValue = aplicationId
      
      const res = await trackPromise(services.api.getPerfilAllPaged(paramns, pagina, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os perfis'
        )
      }
      
      setPerfis(res.data.data)
      setCurrentPage(res.data.pageIndex)
      setPageSize(res.data.pageSize)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllClaimsById = useCallback(async (appId?: number) => {
    try {
      
      const res = await trackPromise(services.api.getClaimAllByAppId(appId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível permissões'
        )
      }
      setPermissoesDiponiveisApp(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number) => {
    getRegistros({page: newPage, busca: busca, pageSize:itensPerPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleBusca = useCallback(async (busca: string, sizePage:number, coluna?: string, desc?: boolean) => {
    getRegistros({page:1, busca: busca, filterColuna: coluna, desc: desc, pageSize: sizePage})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize])

  const editRegistro = useCallback(async (item:IPerfil) => {
    try {
      const res = await trackPromise(services.api.getPerfilAllById(item.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível permissões'
        )
      }
      setPerfilActive(res.data)
      setUserMode(UserMode.edicao)
      setModalPerfil(true)
      setRight(res.data.claims)
      setLeft(not(permissoesDiponiveisApp.filter(item => item), res.data.claims));
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left, permissoesDiponiveisApp])

  const handleRemoverRegistro = async (item:IPerfil) => {
    setPerfilActive(
      {
        id: item.id,
        name: item.name
      }
    )
    setModalRemoverPerfil(true)
  }

  const saveUpdatePerfil = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    function reloadNewUser() {
      setModalPerfil(false)
      getRegistros()
    }

    async function criarPerfil() {
      try {
        const res = await trackPromise(services.api.criarPerfil(perfilActive.name, perfilActive.description, perfilActive.appId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível salvar a Perfil'
          )
        }
        notify('Perfil salva com sucesso', { variant: 'success' })
        reloadNewUser()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    async function editarPerfil() {
      try {
        const res = await trackPromise(services.api.editPerfil(perfilActive.name, perfilActive.description, perfilActive.appId, perfilActive.id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar o Perfil'
          )
        }
        notify('Perfil editado com sucesso', { variant: 'success' })

        setPerfis(
          perfis?.map(item => 
            item.id === perfilActive.id 
            ? {...item, 
              name : perfilActive.name,
              description: perfilActive.description
            }
            : item 
        ))
        setModalPerfil(false)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (perfilActive.id) {
      // edição
      editarPerfil()
    } else {
      // novo
      criarPerfil()
    }
    
  }

  const vincularPerfilComClaim = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    // try {
    //   const res = await trackPromise(services.api.vincularPeriflComClaim(perfilActive.id, 212))
    //   if (res.fail) {
    //     throw new Error(
    //       res.error || 'Não foi possível vincular Permissão'
    //     )
    //   }
    //   notify('Permissão vinculada com sucesso', { variant: 'success' })
    //   // setModalRemoverPerfil(false)
    //   // setPerfis(
    //   //   perfis?.filter(item => item.id !== perfilActive.id 
    //   // ))
    // } catch (error: any) {
    //   notify(error.message, { variant: 'error' })
    // }
    
  }

  const excluirPerfil = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    try {
      const res = await trackPromise(services.api.excluirPerfil(perfilActive.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível excluir o Perfil'
        )
      }
      notify('Perfil excluido com sucesso', { variant: 'success' })
      setModalRemoverPerfil(false)
      setPerfis(
        perfis?.filter(item => item.id !== perfilActive.id 
      ))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }

  /////////////////////////

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(colunaBusca + busca, pageSize)
  }, 800),  [colunaBusca, pageSize])

  const handleChangeBusca = (busca:string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(colunaBusca + search, pageSize, column, direction)
    } else {
      handleBusca(colunaBusca + search, pageSize, colunaAtiva, direction)
    }
  }

  useEffect(() => {
    setFilterList(perfis?.map((item:IPerfil) => {
      return item
    }))
  }, [perfis])

  useEffect(() => {
    setPaginacao(services.utils.getPaginationItens(totalPages, currentPage))
  }, [totalPages, currentPage])

  /////////////////////////
  
  useEffect(() => {
    if(aplicationId) {
      getRegistros()
      getAllClaimsById(aplicationId)
    }
  }, [getRegistros, getAllClaimsById, aplicationId])

  return (
    <>
      <div className='panel'>
        <div className='panel-header'>
          <div className='flex justify-between items-center w-full'>
            <div>Perfis do App</div>
            {userRoles.includes("app_role_create") &&
              <Button
                variant="contained"
                className="btn-main"
                size="small"
                type="button"
                onClick={(e) => newRegister()}
                disabled={promiseInProgress}
                startIcon={<AddCircleIcon style={{color:'white'}} fontSize='small' />}
              >
                NOVO
              </Button>
            }
          </div>
        </div>
        <div className='panel-body'>
          <div className='wrapper-page-content-interna'>
            <div className='flex items-center justify-between gap-10'>
              <div className='flex gap-10'></div>
              
              <div className='flex items-center gap-10'>
                <div className='flex items-center gap-6'>
                  <TextField
                    size='small'
                    className='w-200'
                    label='Pesquisa'
                    placeholder='Pesquisa'
                    inputProps={{ maxLength: 50 }}
                    value={search}
                    onChange={e => handleChangeBusca(e.target.value)}
                    disabled={promiseInProgress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{backgroundColor: '#E21AE7', color: 'white', padding: '2px', marginRight: '-8px', borderRadius: '4px'}}
                            aria-label="toggle password visibility"
                            onClick={(e) => handleClickBusca(e)}
                            edge="end"
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='divider' style={{opacity: 0}}></div>
            <div className='table-container'>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>
                        <span className='th-title'> Name</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'name')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'name')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    <th>
                      <div>
                        <span className='th-title'>Descrição</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'description')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'description')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    <th>
                      <div>
                        <span className='th-title'>Data de adição</span>
                        {ativaOrdenacao &&
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(false,'createAt')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{fontSize: '12px'}}  />
                              
                            </IconButton>
                            <IconButton  
                              size="small" 
                              onClick={() => ordeby(true,'createAt')}
                              style={{padding:'2px'}}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{fontSize: '12px'}}  />
                            </IconButton>
                          </div>
                        }
                      </div>
                    </th>
                    {(userRoles.includes("app_role_update") || userRoles.includes("app_role_delete")) &&
                      <th><div><span className='th-title'>Ações</span></div></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {!filterList && 
                    <tr><td colSpan={4}><div className='justify-center'>loading...</div></td></tr>
                  }
                  {filterList?.length === 0 && 
                    <tr><td colSpan={4}><div className='justify-center'>Nenhum registro encontrado</div></td></tr>
                  }
                  {filterList?.map((item: IPerfil, index: number) => (
                    <tr key={item.id}>
                      <td><div>{item.name}</div></td>
                      <td><div>{item.description}</div></td>
                      <td><div>{item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY HH:mm') : '-'}</div></td>
                      {(userRoles.includes("app_role_update") || userRoles.includes("app_role_delete")) &&
                        <td>
                          <div className='justify-end'>
                            {userRoles.includes("app_role_update") &&
                              <IconButton  
                                size="small" aria-label="editar Perfil" component="label" 
                                onClick={() => editRegistro(item)}
                                disabled={promiseInProgress}
                              >
                                <ModeEditOutlineOutlinedIcon fontSize="small" />
                              </IconButton>
                            }
                            {userRoles.includes("app_role_delete") &&
                              <IconButton  
                                size="small" aria-label="excluir Perfil" component="label" 
                                onClick={() => handleRemoverRegistro(item)}
                                disabled={promiseInProgress}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            }
                          </div>
                        </td>
                      }
                      
                    </tr>
                  ))}
                </tbody>
              </table>
 
              {currentPage && filterList?.length ?
                <div className='flex justify-end items-center gap-10'>
                  <div className='table-pagination'>
                    <IconButton 
                      size="small" aria-label="primeira pagina" component="label" 
                      onClick={() => handlePagination(1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage <= 1}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </IconButton>
                    <IconButton 
                      size="small" aria-label="pagina anterior" component="label" 
                      onClick={() => handlePagination(currentPage - 1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage <= 1}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    {paginacao?.map(page => (
                      <Fragment key={page.toString()}>
                        {page === currentPage ? <div className='table-pagination_number active'>{page.toString()}</div> : 
                        <IconButton 
                          className='table-pagination_number'
                          size="small" aria-label="Próxima pagina" component="label" 
                          onClick={() => handlePagination(page, colunaBusca + search, pageSize )}
                        >
                          {page.toString()}
                        </IconButton>
                      }
                      </Fragment>
                    ))}
                    <IconButton 
                      size="small" aria-label="Próxima pagina" component="label" 
                      onClick={() => handlePagination(currentPage + 1, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage >= totalPages}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                    <IconButton 
                      size="small" aria-label="ultima pagina" component="label" 
                      onClick={() => handlePagination(totalPages, colunaBusca + search, pageSize )}
                      disabled={promiseInProgress || currentPage >= totalPages}
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </IconButton>
                  </div>
                  <Select
                    style={{width: '100px'}}
                    fullWidth
                    size="small"
                    value={pageSize || ''}
                    disabled={promiseInProgress}
                    onChange={e =>
                      handlePagination(1, colunaBusca + search, Number(e.target.value) )
                    }
                  >
                    <MenuItem value="10">10 Itens</MenuItem>
                    <MenuItem value="20">20 Itens</MenuItem>
                    <MenuItem value="50">50 Itens</MenuItem>
                    <MenuItem value="100">100 Itens</MenuItem>
                  </Select>
                </div>  : ''
              }
            </div>
          </div>
        </div>
      </div>
      
      {/* menu opções busca */}
      <Menu
        anchorEl={anchorElBusca}
        open={openBusca}
        onClose={handleCloseBusca}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBusca(e.target.value)}
                value={colunaBusca}
              >
                <FormControlLabel value="name&&&" control={<Radio />} label="Name" />
                <FormControlLabel value="description&&&" control={<Radio />} label="Descrição" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>

      {/* modal Perfil novo/edit */}
      <Modal
        size='md'
        open={modalPerfil}
        close={() => setModalPerfil(false)}
        titulo={userMode === UserMode.edicao ? 'Edição de Perfil' : 'Novo Perfil'}
      >
        <form onSubmit={saveUpdatePerfil} autoComplete="off">
          <Grid container spacing={{ xs: 3 }} id="Modal_app_perfil">
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome do perfil"
                inputProps={{ maxLength: 400 }}
                value={perfilActive.name}
                onChange={e => 
                  setPerfilActive(prevState => ({
                    ...prevState,
                    name: e.target.value.replace(/\s/g, '')
                  }))
                }
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Descrição"
                inputProps={{ maxLength: 400 }}
                value={perfilActive.description}
                onChange={e => 
                  setPerfilActive(prevState => ({
                    ...prevState,
                    description: e.target.value
                  }))
                }
              />
            </Grid>

            {userMode === UserMode.edicao && 
              <Grid item md={12} xs={12}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item>
                    {customList(left, 'Claims Disponíveis', 'left')}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      {/* <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                      >
                        ≫
                      </Button> */}
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0 || promiseInProgress}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0 || promiseInProgress} 
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      {/* <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                      >
                        ≪
                      </Button> */}
                    </Grid>
                  </Grid>
                  <Grid item>
                    {customList(right, 'Claims Associadas', 'right')}
                  </Grid>
                </Grid>
              </Grid>
            }
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalPerfil(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal excluir perfil */}
      <Modal
        size='sm'
        open={modalRemoverPerfil}
        close={() => setModalRemoverPerfil(false)}
        titulo={'Excluir Perfil'}
      >
        <form onSubmit={excluirPerfil} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <div className='flex gap-8 items-center justify-center flex-nowrap'>
                <ErrorOutlineIcon style={{color: 'red'}} />
                <div>Tem certeza que deseja excluir o perfil <strong>{perfilActive.name}</strong>.</div>
              </div>
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalRemoverPerfil(false)}
              >
                cancelar
              </Button> 
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

    </>
  )
}

export default TabelaPerfis
