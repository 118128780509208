import { createContext, useContext, useState } from 'react'

import { LoadingBackdrop } from '../components/Loading'

const LoadingContext = createContext()

const LoadingProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [variant, setVariant] = useState(null)

  const setLoading = variant => {
    if (!open) {
      setVariant(variant)
      setOpen(true)
    }
  }
  const removeLoading = () => {
    setOpen(false)
    setVariant(null)
  }

  return (
    <LoadingContext.Provider
      value={{
        setLoading,
        removeLoading
      }}
    >
      {children}
      <LoadingBackdrop open={open} variant={variant} />
    </LoadingContext.Provider>
  )
}

function useLoading() {
  const context = useContext(LoadingContext)

  return context
}

export { LoadingProvider, useLoading }
