import { Backdrop, CircularProgress } from '@mui/material'

import CircleLogo from '../../assets/img/allis-logo.png'

import './loadingBackdrop.scss'

interface ILoadingBackdrop {
  open: boolean
  variant?: 'logo'
}

export default function LoadingBackdrop(props: ILoadingBackdrop) {
  const { open, variant } = props
  return (
    <div className="loading">
      <Backdrop className={'backdrop_main'} open={open}>
        {variant === 'logo' ? (
          <img
            alt="logo"
            src={CircleLogo}
            className="circleLoading"
            data-testid="circle-loading"
          />
        ) : (
          <CircularProgress
            color="inherit"
            data-testid="circular-progress-loading"
          />
        )}
      </Backdrop>
    </div>
  )
}
