import { useState, useEffect, useCallback } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate } from 'react-router-dom'

import {
  Grid
} from '@mui/material'

import { PageTitle } from '../../components/PageElements'
import CardApp from '../../components/CardApp'
import { useNotification } from '../../hooks/useNotification.js'
import { IAplicacao } from '../../interfaces/index'
import services from '../../services/index.js'

import './style.scss'

function AppsPage() {
  const navigate = useNavigate()
  const { notify } = useNotification()

  const [aplicacoes, setAplicacoes] = useState<IAplicacao[] | []>([])

  const getApps = useCallback(async () => {
    try {
      const res = await trackPromise(services.api.getAplicatoinsAll())
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar as aplicações'
        )
      }
      
      setAplicacoes(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectApp = async (item: IAplicacao) => {
    navigate(`/app/apps/edit/${item.id}`)
  }

  useEffect(() => {
    getApps()
  }, [getApps])

  return (
    <div className='wrapper-container-content bgwhite' >
      <div className='wrapper-page-content' id="wrapper-page-content">
        <PageTitle>
          <div>Gerenciamento de Apps</div>
        </PageTitle>
        
        <Grid container justifyContent="start" spacing={{ xs: 3 }}>
          {aplicacoes?.map((item: IAplicacao, index: number) => (
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={item.id}>
              <CardApp img={item.imageUrl} title={item.name} action={() => handleSelectApp(item)} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default AppsPage
