import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  TextField,
  Menu,
  Button,
  FormControl,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton
} from '@mui/material'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services'

import { IBusca, IAmbiente } from '../../interfaces/index'
import { useAuth } from '../../hooks/useAuth'

interface ISectionAmbientes {
  aplicationId?: number
}

enum UserMode {
  novo,
  edicao
}

function TabelaAmbientes(props: ISectionAmbientes) {
  const { aplicationId } = props;
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [userMode, setUserMode] = useState<UserMode>(UserMode.novo)
  const [modalAmbiente, setModalAmbiente] = useState(false)
  const [modalRemoverAmbiente, setModalRemoverAmbiente] = useState<boolean>(false)
  const [ambientes, setAmbientes] = useState<IAmbiente[] | []>([])
  const [ambienteActive, setAmbienteActive] = useState<IAmbiente>(
    {
      value: "",
      id: 0
    }
  )

  const [anchorElBusca, setAnchorElBusca] = useState<null | HTMLElement>(null);
  const openBusca = Boolean(anchorElBusca);
  const handleCloseBusca = () => {
    setAnchorElBusca(null);
  };

  const newRegister = useCallback(async () => {
    setAmbienteActive(
      {
        id: 0,
        value: "",
        description: "",
        appId: aplicationId,
      }
    )
    setUserMode(UserMode.novo)
    setModalAmbiente(true)
  }, [aplicationId])


  const [colunaBusca, setColunaBusca] = useState<string>('valeu&&&')

  const getAmbientes = useCallback(async (objBusca?: IBusca) => {
    try {
      const res = await trackPromise(services.api.getEnvironmentsAllById(aplicationId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar as Ambientes'
        )
      }
      setAmbientes(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editRegistro = useCallback(async (ambienteItem:IAmbiente) => {
    setAmbienteActive(
      {
        id: ambienteItem.id,
        name: ambienteItem.name,
        baseUrl: ambienteItem.baseUrl,
        appId: ambienteItem.appId,
        jwtToken: ambienteItem.jwtToken
      }
    )
    setUserMode(UserMode.edicao)
    setModalAmbiente(true)
  }, [])

  const handleRemoverRegistro = async (ambienteItem:IAmbiente) => {
    setAmbienteActive(
      {
        id: ambienteItem.id,
        value: ambienteItem.value
      }
    )
    setModalRemoverAmbiente(true)
  }

  const saveUpdateAmbiente = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    function reloadNewUser() {
      setModalAmbiente(false)
      getAmbientes()
    }

    async function criarAmbiente() {
      try {
        const res = await trackPromise(services.api.criarEnvironment(ambienteActive.name, ambienteActive.jwtToken, ambienteActive.baseUrl, aplicationId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível salvar Ambiente'
          )
        }
        notify('Ambiente salvo com sucesso', { variant: 'success' })
        reloadNewUser()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    async function editarAmbiente() {
      try {
        const res = await trackPromise(services.api.editarEnvironment(ambienteActive.name, ambienteActive.jwtToken, ambienteActive.baseUrl, aplicationId, ambienteActive.id ))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar Ambiente'
          )
        }
        notify('Ambiente editada com sucesso', { variant: 'success' })

        setAmbientes(
          ambientes.map(item => 
            item.id === ambienteActive.id 
            ? {...item, 
              name : ambienteActive.name,
              jwtToken: ambienteActive.jwtToken,
              baseUrl: ambienteActive.baseUrl
            }
            : item 
        ))
        setModalAmbiente(false)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (ambienteActive.id) {
      // edição
      editarAmbiente()
    } else {
      // novo
      criarAmbiente()
    }
    
  }

  const excluirAmbiente = async (e:React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault()

    try {
      const res = await trackPromise(services.api.deleteEnvironment(ambienteActive.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível excluir Ambiente'
        )
      }
      notify('Ambiente excluido com sucesso', { variant: 'success' })
      setModalRemoverAmbiente(false)
      setAmbientes(
        ambientes.filter(item => item.id !== ambienteActive.id 
      ))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    
  }
  
  useEffect(() => {
    if(aplicationId) {
      getAmbientes()
    }
  }, [getAmbientes, aplicationId])

  return (
    <>
      <div className='panel'>
        <div className='panel-header'>
          <div className='flex justify-between items-center w-full'>
            <div>Ambientes</div>
            {userRoles.includes("app_env_create") && 
              <Button
                variant="contained"
                className="btn-main"
                size="small"
                type="button"
                onClick={(e) => newRegister()}
                disabled={promiseInProgress}
                startIcon={<AddCircleIcon style={{color:'white'}} fontSize='small' />}
              >
                NOVO
              </Button>
            }
          </div>
        </div>
        
        {ambientes.map((item: IAmbiente, index: number) => (
          <Fragment key={item.id}>
            {index > 0 ? <div className='divider'></div> : ''}
            <div className='panel-body'>
              <div className='wrapper-page-content-interna'>
                <Grid container spacing={{ xs: 3 }} key={item.id}>
                  <Grid item md={1} xs={2} >
                    <div className='flex gap-8 flex-col items-center'>
                      {userRoles.includes("app_env_update") && 
                        <IconButton  
                          style={{backgroundColor: '#E21AE7', color: 'white', padding: '4px',  borderRadius: '4px'}}

                          size="small" aria-label="editar Ambiente" component="label" 
                          onClick={() => editRegistro(item)}
                          disabled={promiseInProgress}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      }
                      {userRoles.includes("app_env_delete") && 
                        <IconButton  
                          style={{backgroundColor: '#E21AE7', color: 'white', padding: '4px',  borderRadius: '4px'}}

                          size="small" aria-label="excluir Ambiente" component="label" 
                          onClick={() => handleRemoverRegistro(item)}
                          disabled={promiseInProgress}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      }
                    </div>
                  </Grid>
                  <Grid item md={11} xs={10}>
                    <Grid container spacing={{ xs: 3 }}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="Nome"
                          disabled
                          value={item.name}
                        />
                      </Grid>
                      
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="URL Base"
                          disabled
                          value={item.baseUrl}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          required
                          size='small'
                          label="Token JWT"
                          disabled
                          value={item.jwtToken}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Fragment>
        ))}
        
      </div>
      
      {/* menu opções busca */}
      <Menu
        anchorEl={anchorElBusca}
        open={openBusca}
        onClose={handleCloseBusca}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{padding: '2px 8px', fontSize: '14px'}}>
            Buscar por
          </div>
          <div style={{padding: '2px 8px'}}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBusca(e.target.value)}
                value={colunaBusca}
              >
                <FormControlLabel value="value&&&" control={<Radio />} label="Valor" />
                <FormControlLabel value="description&&&" control={<Radio />} label="Descrição" />
              </RadioGroup>
            </FormControl>   
          </div>
        </div>
        
      </Menu>

       {/* modal Ambiente novo/edit */}
       <Modal
        size='sm'
        open={modalAmbiente}
        close={() => setModalAmbiente(false)}
        titulo={userMode === UserMode.edicao ? 'Edição de Ambiente' : 'Novo Ambiente'}
      >
        <form onSubmit={saveUpdateAmbiente} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Nome"
                inputProps={{ maxLength: 400 }}
                value={ambienteActive.name}
                onChange={e => 
                  setAmbienteActive(prevState => ({
                    ...prevState,
                    name: e.target.value
                  }))
                }
              />
            </Grid>
            
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="URL Base"
                inputProps={{ maxLength: 400 }}
                value={ambienteActive.baseUrl}
                onChange={e => 
                  setAmbienteActive(prevState => ({
                    ...prevState,
                    baseUrl: e.target.value.replace(/\s/g, '')
                  }))
                }
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                size='small'
                label="Token JWT"
                inputProps={{ maxLength: 400 }}
                value={ambienteActive.jwtToken}
                onChange={e => 
                  setAmbienteActive(prevState => ({
                    ...prevState,
                    jwtToken: e.target.value.replace(/\s/g, '')
                  }))
                }
              />
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalAmbiente(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                SALVAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal excluir Ambiente */}
      <Modal
        size='sm'
        open={modalRemoverAmbiente}
        close={() => setModalRemoverAmbiente(false)}
        titulo={'Excluir Ambiente'}
      >
        <form onSubmit={excluirAmbiente} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item md={12} xs={12}>
              <div className='flex gap-8 items-center justify-center flex-nowrap'>
                <ErrorOutlineIcon style={{color: 'red'}} />
                <div>Tem certeza que deseja excluir o Ambiente <strong>{ambienteActive.value}</strong>.</div>
              </div>
            </Grid>
            
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size='small'
                color="inherit"
                onClick={() => setModalRemoverAmbiente(false)}
              >
                cancelar
              </Button> 
              <Button
                variant="contained"
                type="submit"
                size='small'
                color="secondary"
                disabled={promiseInProgress}
              >
                Excluir
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

    </>
  )
}

export default TabelaAmbientes
