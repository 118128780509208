import { usePromiseTracker } from 'react-promise-tracker'

import LinearProgress from '@mui/material/LinearProgress'

import './loadingBar.scss'

const LoadingBar = () => {
  const { promiseInProgress } = usePromiseTracker()
  return (
    <>
      {promiseInProgress && (
        <div data-testid="loadingBarId" className="loadPage">
          <LinearProgress />
        </div>
      )}
    </>
  )
}

export default LoadingBar
