import { createContext, useContext } from 'react'

import Cookies from 'js-cookie'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const getToken = () => {}

  const getUserRoles = () => {
    const userObj = Cookies.get('TradeTalentosUserPAT')
    if (!userObj) return null

    const myDecodedToken = JSON.parse(userObj)
    if (myDecodedToken?.role) {
      return myDecodedToken.role
    }
  }

  return (
    <AuthContext.Provider
      value={{
        getToken,
        getUserRoles
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export { AuthProvider, useAuth }
