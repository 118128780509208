import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RequireAuth from '../layouts/RequireAuth'
import Login from '../views/Login'
import NotFound from '../views/NotFound'
import Usuarios from '../views/Usuarios'
import DetalheUsuario from '../views/Usuarios/EditUser'
import AccessDenied from '../views/AccessDenied'

import Cargos from '../views/Cargos'
import Logs from '../views/Logs'
import AppsPage from '../views/Apps'
import AppsPageEdit from '../views/Apps/EditApp'

function SystemRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/app/users" element={<RequireAuth roles={['user_read','user_create','user_update']} />}>
          <Route path="/app/users/todos" element={<Usuarios />} />
          <Route path="/app/users/user/:id" element={<DetalheUsuario />} />
        </Route>
        
        <Route path="/app/cargos" element={<RequireAuth roles={['function_read','function_create', 'function_update', 'function_delete']} />}>
          <Route path="/app/cargos/todos" element={<Cargos />} />
        </Route>

        <Route path="/app/apps" element={<RequireAuth roles={['app_read','app_create', 'app_update','app_delete']} />}>
          <Route path="/app/apps/todos" element={<AppsPage />} />
          <Route path="/app/apps/edit/:id" element={<AppsPageEdit />} />
        </Route>

        <Route path="/app/logs" element={<RequireAuth roles={['log_action_read','log_login_read']} />}>
          <Route path="/app/logs/todos" element={<Logs />} />
        </Route>
        
        <Route path="/nao-autorizado" element={<AccessDenied />} />
        
        <Route path="/auth" element={<Login />}>
          <Route path="/auth" element={<Login />} />
          <Route path="/auth/signin" element={<Login />} />
        </Route>
        <Route path="/" element={<Login />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default SystemRoutes
