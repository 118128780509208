// import { appConfig } from '../configs/env-config.js'

// export const lojasPropriasComZLPL = appConfig.REACT_APP_LojasPropriasComZLPL

export const openExternalLink = async (link, aba = '_blank') => {
  if (link) {
    window.open(link, aba)
  }
}

export const findIndexInArray = (valueToFind, listArray, parameter) => {
  if (!valueToFind || !listArray) {
    return -1
  }
  if (parameter) {
    for (let x = 0; x < listArray.length; x++) {
      if (listArray[x][parameter].toString() === valueToFind.toString()) {
        return x
      }
    }
    return -1
  }
  for (let x = 0; x < listArray.length; x++) {
    if (listArray[x].toString() === valueToFind.toString()) {
      return x
    }
  }
  return -1
}

/**
 * function orderbyArrayOfObjects, reorder array of objects
  @param {string} property - parametro que será utilizado para ordenar 'b'
  @param {array} list - lista de objetos [{a:19,b:2},{a:15,b:9},{a:1,b:80}]
  @param {bool} revert - se for true ordena de forma decrecente
 **/
export const orderbyArrayOfObjects = (property, list, revert = false) => {
  let sortOrder = revert ? -1 : 1
  if (property[0] === '-') {
    sortOrder = 1
    property = property.substr(1)
  }

  return list.sort((a, b) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    return result * sortOrder
  })
}

/**
 * function soma, retorna soma dos valores enviados em array
  @param {array} valores - lista de valores tipo number [10, 15.80, 1999.89]
 **/
export const somaValores = valores => {
  const resultadoSoma = valores.reduce((total, item) => total + Number(item), 0)
  if (!resultadoSoma) return 0
  return parseFloat(resultadoSoma).toFixed(2)
}

export const convertDateIsoToDate = (date, format = false) => {
  if (format === false) {
    return new Date(
      date.substring(0, 4),
      date.substring(5, 7) - 1,
      date.substring(8, 10),
      date.substring(11, 13),
      date.substring(14, 16),
      date.substring(17, 19)
    )
  } else {
    return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(
      0,
      4
    )}`
  }
}

export const getPageAmount = (totalItens, pageSize) => {
  if (totalItens >= 0 && pageSize >= 0) {
    let d = totalItens / pageSize
    if (d < 1) return 1
    if (Number.isInteger(d)) return d
    return Number(d.toFixed(0)) + 1
  } else {
    return 0
  }
}

export const getPaginationItens = (totalPages, currentPage) => {
  let pagination = []
  for (let i = 1; i <= totalPages; i++) {
    pagination.push(i)
  }
  if (currentPage + 1 <= 6) {
    pagination = pagination.slice(0, 7)
  } else {
    let pr = Number(currentPage) - 3
    let pn = Number(currentPage) + 3
    if (Number(totalPages) - Number(currentPage) < 5) {
      pagination = pagination.slice(Number(totalPages) - 6, totalPages)
    } else {
      pagination = pagination.slice(pr, pn)
    }
  }
  return pagination
}

export const editObjTermoBusca = (busca, objetoBusca) => {
  objetoBusca.search = ''
  if (!busca || !busca.includes('&&&')) {
    return objetoBusca
  }
  const splitBusca = busca.split('&&&')
  if (!splitBusca.length) {
    return objetoBusca
  }
  if (splitBusca.length === 2) {
    if (!splitBusca[1]) {
      return objetoBusca
    }
    objetoBusca.search = `${splitBusca[0]}=${splitBusca[1]}`
    return objetoBusca
  }
  return objetoBusca
}
