import React, { useState, useEffect } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';

import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import { ICargo, IUser } from '../../interfaces/index'

import services from '../../services/index.js'

import './style.scss'
import { cpfCnpj, phone } from '../../services/mask'
import moment from 'moment/moment'
import { Lock, LockOpen } from '@mui/icons-material'

interface IInfoUser {
  user: IUser
  cargos: Array<ICargo>
  setUser: React.Dispatch<React.SetStateAction<IUser>>
  newUser: boolean
}

interface IUserSimplificado {
  id?: number
  fullName?: string
}


function InfoUser(props: IInfoUser) {
  const { user, setUser, cargos, newUser } = props;
  const { promiseInProgress } = usePromiseTracker();
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [itemSelecionado, setItemSelecionado] = useState<IUserSimplificado>({})
  const [usuarios, setUsuarios] = useState<IUserSimplificado[] | []>([])
  const [usuariosFiltrados, setUsuariosFiltrados] = useState<IUserSimplificado[] | []>([])

  // const getUsuarios = useCallback(async (objBusca?: string) => {
  //   try {
  //     const pagina = 1
  //     const termoBusca = objBusca ? objBusca : ''
  //     const orderBycolumn = ''
  //     const desc = ''
  //     const tamanhoPagina = 20
  //     let paramns = services.utils.editObjTermoBusca(termoBusca, {orderByColuna: orderBycolumn, desc: desc})
  //     const res = await trackPromise(services.api.getUsersAll(paramns, pagina, tamanhoPagina))
  //     if (res.fail) {
  //       throw new Error(
  //         res.error || 'Não foi possível consultar Usuários'
  //       )
  //     }
  //     setUsuarios(res.data.data)
  //   } catch (error: any) {
  //     //notify(error.message, { variant: 'error' })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const buscarCliente = useCallback(debounce((busca) => {
  //   if (busca.length > 2) {
  //     getUsuarios(`fullName&&&${busca}`)
  //   }
  // }, 800),  [])

  const filtrarSuperior = (busca: string) => {
    if(busca) {
      setUsuariosFiltrados(usuarios.filter((item) => item.fullName?.toUpperCase().includes(busca.toUpperCase()) ))
    } else {
      setUsuariosFiltrados(usuarios.filter((item) => item))
    }
  }

  async function unblockUserOnSra() {
    try {
      const res = await trackPromise(services.api.unblockUserOnSra(user.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível desbloquear o usuário'
        )
      }

      setUser(prevState => ({
        ...prevState,
        sraBlocked: false
      }))
      
      setUser(prevState => ({
        ...prevState,
        skipSraCheck: true
      }))

      notify("Usuário desbloqueado", { variant: 'success' })

    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const retornaItem = (item: any) => {
    if (item) {
      setUser(prevState => ({
        ...prevState,
        parentUserId: item.id,
        parentUser: item
      }))
      setItemSelecionado(item)
    } else {
      setUser(prevState => ({
        ...prevState,
        parentUserId: '',
        parentUser: null
      }))
      setItemSelecionado({})
    }
  }

  const changeCargo = async (value: any) => {
    setUser(prevState => ({
      ...prevState,
      functionId: value ? value : ''
    }))
    retornaItem(null)

    
  }

  // useEffect(() => {
  //   getUsuarios('')
  // }, [getUsuarios])


  useEffect(() => {
    const init = async () => {
      try {
        const res = await trackPromise(services.api.getUserAssignable(user.functionId, user.id ? user.id : null))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Usuarios superiores'
          )
        }

        if(!itemSelecionado?.id && user.parentUserId) {
          const findUser = res.data.find((item: IUserSimplificado) => item.id === user.parentUserId)
          if (findUser) {
            setItemSelecionado(findUser)
          }
          
        }
        
        setUsuarios(res.data)
        setUsuariosFiltrados(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
    if (user.functionId) {
      init()
    } else {
      setUsuarios([])
      setUsuariosFiltrados([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.functionId])

  return (
    
      <Grid container justifyContent="center" spacing={{ xs: 3 }}>
        
        <Grid item md={10} xs={12}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item lg={4} md={12} xs={12}>
              <TextField
                fullWidth
                required
                label="Nome Completo"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.fullName}
                onChange={e =>
                  setUser(prevState => ({
                    ...prevState,
                    fullName: e.target.value
                  }))
                }
              />
            </Grid>



            <Grid item lg={4} md={12} xs={12}>
              <TextField
                fullWidth
                required
                label="Email"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.email}
                onChange={e => 
                  setUser(prevState => ({
                    ...prevState,
                    email: e.target.value
                  }))
                }
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Username"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.username}
                onChange={e =>
                  setUser(prevState => ({
                    ...prevState,
                    username: e.target.value
                  }))
                }
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                label="CPF"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.cpf}
                onChange={e =>
                  setUser(prevState => ({
                    ...prevState,
                    cpf: cpfCnpj(e.target.value)
                  }))
                }
              />
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                label="Telefone"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={phone(user.phone)}
                onChange={e =>
                  setUser(prevState => ({
                    ...prevState,
                    phone: phone(e.target.value)
                  }))
                }
              />
            </Grid>



            <Grid item lg={4} md={6} xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Interno</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  required
                  size="small"
                  label="Interno"
                  value={user.internal || false}
                  onChange={e => 
                    setUser(prevState => ({
                      ...prevState,
                      internal: e.target.value === 'true' ? true : false
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  <MenuItem value="true" >
                    Sim
                  </MenuItem>
                  <MenuItem value="false" >
                    Não
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid item lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Empresa"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.company}
                onChange={e => 
                  setUser(prevState => ({
                    ...prevState,
                    company: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Ativo</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  required
                  size="small"
                  label="Ativo"
                  value={user.active || false}
                  onChange={e => 
                    setUser(prevState => ({
                      ...prevState,
                      active: e.target.value === 'true' ? true : false
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  <MenuItem value="true" >
                    Sim
                  </MenuItem>
                  <MenuItem value="false" >
                    Não
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

           
            {/* <Grid item lg={5} md={6} xs={12}>
              <Autocomplete
                style={{minWidth: '200px'}}
                value={itemSelecionado}
                onChange={(event, newValue) => {
                  retornaItem(newValue)
                }}
                isOptionEqualToValue={(option:any, value: any) =>
                  option.fullName === value.fullName
                }
                getOptionLabel={(option:any) => option?.fullName || ''}
                options={usuariosFiltrados}
                disabled={promiseInProgress || !user.functionId }
                noOptionsText={'Buscar'}
                loading={false}
                renderInput={params => (
                  <TextField
                    label="Superior Direto"
                    {...params}
                    size="small"
                    onChange={e => filtrarSuperior(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item lg={7} md={6} xs={12}>
              <FormControl>
                <InputLabel id="input_cargo_label">Cargo</InputLabel>
                <Select
                  labelId="input_cargo_label"
                  id="input_cargo"
                  fullWidth
                  size="small"
                  label="Cargo"
                  disabled
                  value={user.functionId || ''}
                  onChange={e =>
                    changeCargo(e.target.value)
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  {cargos.map((item:ICargo) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item lg={4} md={6} xs={12}>
              <TextField
                fullWidth
                required
                label="Expiração da senha"
                size='small'
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 400 }}
                value={user.expirationDays}
                onChange={e => 
                  setUser(prevState => ({
                    ...prevState,
                    expirationDays: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Alterar senha no login</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  required
                  size="small"
                  label="Alterar senha no login"
                  value={user.updatePasswordOnLogin || false}
                  onChange={e => 
                    setUser(prevState => ({
                      ...prevState,
                      updatePasswordOnLogin: e.target.value === 'true' ? true : false
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  <MenuItem value="true" >
                    Sim
                  </MenuItem>
                  <MenuItem value="false" >
                    Não
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={6} xs={12}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">MFA habilitado</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  fullWidth
                  required
                  size="small"
                  label="MFA habilitado"
                  value={user.mfaEnabled || false}
                  onChange={e =>
                    setUser(prevState => ({
                      ...prevState,
                      mfaEnabled: e.target.value === 'true' ? true : false
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  <MenuItem value="true" >
                    Sim
                  </MenuItem>
                  <MenuItem value="false" >
                    Não
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            
            {/* <Grid item lg={6} md={6} xs={12}>
              <FormControl>
                <InputLabel id="input_role_label">Portal Role</InputLabel>
                <Select
                  labelId="input_role_label"
                  id="input_role"
                  fullWidth
                  required
                  size="small"
                  label="Portal Role"
                  value={user.portalRole || ''}
                  onChange={e =>
                    setUser(prevState => ({
                      ...prevState,
                      portalRole: e.target.value ? e.target.value : ''
                    }))
                  }
                >
                  <MenuItem value="" >
                    Selecione
                  </MenuItem>
                  <MenuItem value="ADMINISTRATOR" >
                    ADMINISTRATOR
                  </MenuItem>
                  <MenuItem value="CUSTOMER_ADMIN" >
                    CUSTOMER_ADMIN
                  </MenuItem>
                  <MenuItem value="READ_ONLY" >
                    READ_ONLY
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {!newUser ? 
              <Grid item lg={4} md={6} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">Bloqueado por falta de login</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    fullWidth
                    required
                    size="small"
                    label="Bloqueado por falta de login"
                    value={user.disabledByLoginTime || false}
                    disabled
                    onChange={e =>
                      setUser(prevState => ({
                        ...prevState,
                        disabledByLoginTime: e.target.value === 'true' ? true : false
                      }))
                    }
                  >
                    <MenuItem value="" >
                      Selecione
                    </MenuItem>
                    <MenuItem value="true" >
                      Sim
                    </MenuItem>
                    <MenuItem value="false" >
                      Não
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>: '' 
            }

            {!newUser ?
              <Grid item lg={4} md={6} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-autowidth-label">Ignorar bloqueio da SRA</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    fullWidth
                    required
                    size="small"
                    label="Bloqueado por falta de login"
                    value={user.skipSraCheck || false}
                    onChange={e =>
                      setUser(prevState => ({
                        ...prevState,
                        skipSraCheck: e.target.value === 'true' ? true : false
                      }))
                    }
                  >
                    <MenuItem value="" >
                      Selecione
                    </MenuItem>
                    <MenuItem value="true" >
                      Sim
                    </MenuItem>
                    <MenuItem value="false" >
                      Não
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>: ''
            }


            {!newUser ?
              <Grid item lg={4} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Próxima troca de senha"
                  size='small'
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 400 }}
                  value={user.nextExpirationDate ? moment(user.nextExpirationDate).format('DD/MM/YYYY HH:mm') : '-'}
                  disabled={true}
                />
              </Grid> : ''
            }

            {newUser ? 
              <>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Senha"
                    size='small'
                    type="password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 400 }}
                    value={user.password  || ''}
                    onChange={e => 
                      setUser(prevState => ({
                        ...prevState,
                        password: e.target.value
                      }))
                    }
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Confirmar Senha"
                    size='small'
                    type="password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 400 }}
                    value={user.passwordConfirm || ''}
                    onChange={e => 
                      setUser(prevState => ({
                        ...prevState,
                        passwordConfirm: e.target.value
                      }))
                    }
                  />
                </Grid>
              </> : ''
            }

            {(userRoles.includes("user_create") || userRoles.includes("user_update")) && !user.sraBlocked && 
              <Grid item lg={12} md={12} xs={12} className="flex justify-end">
                <Button
                  variant="contained"
                  className="btn-main"
                  type="submit"
                  id="btn_login_submit"
                  size="large"
                  disabled={promiseInProgress}
                  startIcon={<SaveIcon />}
                >
                  SALVAR
                </Button>
              </Grid>
            }

            

              {user.sraBlocked ? <Grid item md={12} xs={12}> <div style={{backgroundColor: 'rgba(255,0,0,0.54)', color: 'white', padding: '1px', borderRadius: '10px', marginBottom: '20px'  }}>
                  <h4 style={{textAlign: 'left', margin: '20px', marginBottom: '-30px'}}>Este usuário esta bloqueado por demissão</h4>
                <div style={{textAlign: 'end', marginBottom: '10px', marginRight: '10px'}}>
                  <Button
                    style={{background: 'rgb(179,4,4)'}}
                    variant="contained"
                    className="btn-main bgwhite"
                    type="submit"
                    onClick={unblockUserOnSra}
                    size="large"
                    disabled={promiseInProgress}
                    startIcon={<LockOpen />}
                  >
                    Desbloquear
                  </Button>
                </div>

              </div></Grid>
                : ''}
            
            

          </Grid>
        </Grid>
      </Grid>
  )
}

export default InfoUser
